/* SOCIAL FEED
========================================================================== */

.socialFeed {
	@extend %section;
	@extend .h-softLgEnds;
	&--twitter {
		background:$colorGreyLightest;
		.socialFeed-user {
			@extend .icon-twitter;
			&:before {color:#55acee}
		}
	}
	&--facebook {
		background:$colorGreyLightest;
		.socialFeed-user {
			@extend .icon-facebook;
			&:before {color:#3b5998}
		}
	}
}
.socialFeed-contained {
	//@extend %contained;
	@extend %noPhatBottom;
	max-width:600px;
	//counter the bottom margin applied to slick-slider
	//margin-bottom:0;
}
.socialFeed-item {
	@extend %noPhatBottom;
	@extend %clearfix;
	padding-bottom:$spacingBase;
	margin-bottom:$spacingBase;
}
.socialFeed-user {
	@include font-size(16px);
	margin-bottom:0;
	&:before {
		@extend %iconStyle;
	}
}
.socialFeed-figure {
	text-align:left;
	margin-bottom:0;
}
.socialFeed-infoList, .socialFeed-user {
	margin-bottom:$spacingXSm;
}
// .socialFeed-infoList {
// 	color:$colorWhite;
// }
//style the slick slider dots color/placement
// .socialFeed .slick-dots {
// 	bottom:-$spacingBase;
// 	li button {
// 		&:before {
// 			background-color:$colorWhite;
// 		}
// 	    &.slick-active button:before {
// 	        background-color: $colorWhite;
// 	    }
// 	}
// }
 @include mq(med) {
 	.socialFeed-content {
 		overflow:hidden;
 	}
 	.socialFeed-figure {
 		float:left;
 		margin-right: $spacingSm;
 		margin-bottom:0;
 		max-width: 35%;
 		text-align:center;
 	}
 }