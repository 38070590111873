/* <IMG>
========================================================================== */

/*
 * Fluid images.
 */
img {
    display: inline-block;

    max-width: 100%;

    vertical-align: middle;
}
