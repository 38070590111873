/* ALERTS
========================================================================== */

/*
    <p class="alert">...</p>

    <div class="alert">...</div>
*/

.alert {
    position: relative;

    display: block;

    padding: $spacingXSm $spacingSm;

    color: $colorWhite;
    background: $colorHighlight;

    a {
        text-decoration: underline;

        color: $colorWhite;
    }
    &:before {
        height: auto;
        margin-right: $spacingXSm;
    }
    &[class^='icon'],
    &[class*=' icon'] {
        padding-left: $spacingLg+12;

        &:before {
            @extend %flexbox;

            @include align-items(center);
            @include justify-content(center);

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;

            width: $spacingLg;

            font-size: 125%;

            background: rgba($colorWhite,.15);
        }
    }
}
.alert--danger {
    background: $colorDanger;
}
.alert--success {
    background: $colorSuccess;
}
.alert--warning {
    background: $colorWarning;
}
/*
* Self closing alerts
*
    <p class="alert is-closeable">...</p>
*
* Script Library/front-end/isCloseable.js
* On click, the alert gets wiped out through javascript
* .net devs would need to be advised about making the closing persistent
*/

.alert.is-closeable {
    cursor: pointer;

    &:after {
        position: absolute;
        top: $spacingXSm;
        right: $spacingXSm;

        display: inline-block;

        margin: 0 0 0 .4em;
        padding-right: .4em;
        padding-left: .4em;

        content: 'X';

    }
    &:hover {
        color: $colorWhite;
        background: $colorDanger;

        &:after {
            color: $colorWhite;
        }
    }
}



/*
* Self closing alerts
*
    <p class="alert alert--closeable">...</p>
*
* The .alert-close link is added dynamically to any alert with the .alert--closeable class
* through js in Script Library/PageScripts/frontEnd.js
* On click, the alert gets wiped out through javascript
* .net devs would need to be advised about making the closing persistent
*/
.alert-close {
    position: absolute;
    top: $spacingXSm;
    right: $spacingSm;

    text-decoration: none;

    &:after {
        @extend %iconStyle;

        margin-right: 0;

        content: '\2715';
    }
    .no-touch &:hover {
        color: $colorWhite;
    }
}
