/* STYLE GUIDE SPECIFIC STYLES
========================================================================== */

.styleguide-main {
    @extend %section;
}
.styleSection {
    @extend %contained;

    display: none;

    margin-top: 100px;
    margin-bottom: 100px;

    &.show {
        display: block;
    }
}
.styleSectionTitle {
    @include font-size($h1Size);
}
@include mq(lg) {
    .styleSectionTitle {
        position: relative;

        &:before {
            position: absolute;
            top: 50%;
            right: 102%;

            width: 0;
            height: 0;
            margin-top: -600px;

            content: '';

            border-top: 600px solid transparent;
            border-bottom: 600px solid transparent;
            border-left: 700px solid $colorGreyLightest;
        }
    }
}
.example {
    @extend %clearfix;
    @extend %noPhatBottom;

    margin-bottom: $lineHeight;
}
.example--dark {
    padding: $spacingSm;

    background: $colorGreyDark;
}
.example-summary {
    @include font-size($h4Size);

    margin-bottom: $lineHeight;
    padding-left: $spacingSm;

    font-family: $fontHighlight;

    color: $colorGrey;
    border-left: 4px solid $colorHighlight;
}

code {
    @include font-size($fontSizeSm);

    position: relative;

    display: block;
    overflow: auto;

    padding: 2em;

    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 2.0000em;

    color: $colorGreyDark;
    background: #f9f9f9;
    background-image: -webkit-linear-gradient($colorGreyLighter 50%, $colorGreyLightest 50%);
    background-image:    -moz-linear-gradient($colorGreyLighter 50%, $colorGreyLightest 50%);
    background-image:     -ms-linear-gradient($colorGreyLighter 50%, $colorGreyLightest 50%);
    background-image:      -o-linear-gradient($colorGreyLighter 50%, $colorGreyLightest 50%);
    background-image:         linear-gradient($colorGreyLighter 50%, $colorGreyLightest 50%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 4.0000em  4.0000em;

    &:before {
        @include font-size($fontSizeSm);

        position: absolute;
        top: 1px;
        left: 1px;

        display: inline-block;

        padding-right: $spacingXSm;
        padding-left: $spacingXSm;

        content: attr(rel);
        letter-spacing: 1px;
        text-transform: uppercase;

        background-color: $colorWhite;
    }
}
.gridDemo [class*=column] {
    position: relative;

    margin-bottom: $lineHeight;

    &::before,
    &::after {
        display: block;
    }
    &::before {
        z-index: -1;

        display: block;

        height: 2em;

        content: '';

        background: $colorGreyLight;
    }
    &::after {
        @include font-size($fontSizeSm);

        margin: 0 10px;

        content: attr(class);
        word-spacing: 15px;
    }
}
.styleguide-colorList {
    @include font-size(14px);
    @include flexbox;

    text-align: center;

    li {
        @include flex(1 1 0);

        position: relative;

        border-bottom: $lineHeight*2 solid;
    }
}
//style the spans with the JS generated hex value
.styleguide-color {
    display:block;

}
//style icons list from fontello
.i-code {
    display: none;
}
.demo-icon {
    font-size: 0;

    &:before {
        @include font-size(16px);

        margin-right: .3em !important;
    }

    //&:before {
    //    display:inline-block;
    //}
}
