/* MARKETING DOWNLOAD
========================================================================== */
.marketingDownload {
	clear:both;
}
.marketingDownload-inner {
    overflow: hidden;
    max-width:$maxWidth;
    margin:auto;
    /*
        * Always use a figure to contain the image
        */
    figure {
        float: left;
        max-width: 40%;
        margin-right: $spacingLg;
        margin-bottom: 0;

        text-align: center;
        img {
            height: auto;
        }
        /*
            * Accomodate responsive videos
            */
        &.figure--video {
            width: 35%;
            height: 200px;
            padding-top: 200px;
        }
    }
    /*
        * The content to the right of the image
        */
    .marketingDownload-body {
        overflow: hidden;
        .form-body {
        	padding-top:$spacingBase;
        }
    }
    /*
    * Place the image/video on the right side instead of the left
    */
    .marketingDownload--imgRight & {
        figure {
            float: right;

            margin-right: 0;
            margin-left: $spacingLg !important;
        }
    }	
    .marketingDownload-header {
    	margin-bottom:$spacingXSm;
        @include font-size(24px);
        line-height:1.4;        

    }
    .marketingDownload-subheader {
    	font-size:$h5Size;
    	font-weight:300;
    	margin-bottom:$spacingXSm;
    	color:$colorGreyDark;
    }
}