/*  STRIKETHROUGH
========================================================================== */

/*
 * Strike text.
 */

.h-strikethrough {
    text-decoration: line-through !important;
}




