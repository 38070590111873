
/* ACCORDION
========================================================================== */

.accordion {
    margin: -($spacingBase) $spacingBase;
    padding:0;
    @include mq(lg) {
        margin:-($spacingLg*1.5) 0;
    }
    list-style: none;

    > li {
        @extend %flexbox;

        @include flex-wrap(wrap);

        span {
            @extend %flexbox;

            @include flex(1 1 auto);
            @include font-size(20px);
            @include align-items(center);

            position: relative;

            padding: $spacingSm;

            cursor: pointer;
            // text-transform: uppercase;
            font-weight: normal;
            line-height: 1.4;

            //define the color so it doesnt change if the container has a dark background
            color: $colorText;
            border-bottom: 1px solid $colorBorder;

            &:before {
                @include flex(0 0 50px);
                @include font-size(16px);

                margin: 0 0 0 (-$spacingSm);

                line-height: inherit;
            }
            &:after {
                @extend %iconStyle;

                width: auto;
                margin-right: 0;
                margin-left: auto;
                padding: 0 $spacingSm;

                content: '\2b';
                line-height: 1.4;
            }
        }
        &:hover {
            span {
                color: $colorBrandPrimary;
            }
        }
        &.is-active {
            span {
                margin-bottom: 0;

                color: $colorBrandPrimary;
                border-width: 0;

                &:after {
                    content: '\2d';
                }
            }
        }
        &:last-child > span,
        &:last-child > .accordion-content {
            border-bottom-width: 0;
        }
    }
}
.accordion-content {
    @extend %noPhatBottom;

    transition:all .2s ease-in-out;
    @include flex(0 0 100%);
    @include font-size(16px);

    overflow: hidden;

    max-height: 0;

    -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top center;
        -ms-transform-origin: top center;
            transform-origin: top center;

    color: $colorGreyDarkest;
    p { margin-bottom:$spacingSm;}
    ul li {
        list-style-type:disc;
        li {list-style-type:circle;}
    }
}
.is-active .accordion-content {
    max-height: none;
    padding-right: $spacingBase;
    padding-bottom: $spacingBase;
    padding-left: $spacingBase;

    -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
            transform: scale(1, 1);

    border-bottom: 1px solid $colorBorder;
}
