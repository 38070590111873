/* iApps Styles
========================================================================== */
.navUtility.iapps-nav-container {
    margin:38px 0 0 0;
    display:flex;
    width:100%;
    justify-content:flex-end;
    li {
        width:200px;
        text-align:right;
        padding-right:35px;
        &.btn {
            color:$colorWhite;
            max-height:45px;
            a {color:$colorWhite;}
        }
    }
}
