/* REVIEWS
========================================================================== */
.reviewsRotator {
	padding:$spacingBase;
	.contained {
		text-align:center;
		max-width:800px;
		margin:auto;

		
	}
	@include mq(med) {
		padding:$spacingLg 0;
	}
}
.reviewsRotator-content {
	display:none;
	@extend .contentSlider-content;
	padding:0;
	p {margin:0 0 $spacingXSm 0;}
}
.reviewsRotator-link {
	margin-top:$spacingBase;
}

// Needs differentiator in order to override slick slide
.slick-slide.reviewsRotator-item {
	@extend .slick-slide.contentSlider-item;
	@include font-size(26px);
	line-height:1.5;
}
// These styles affect Reviews Listing & Reviews Rotator
.reviews-heading {
	@extend .featureBlock-heading;
	@include font-size(26px);
	line-height:1.4;
	margin:0 auto $spacingXSm auto;
}
p.reviews-rating {
	color:$colorAGYellow;
	margin: 0 0 $spacingBase 0;
	a {
		@include font-size(14px);
		margin:0 0 0 $spacingXSm;
	}
	span {background:$colorBrandPrimary; color:$colorWhite;}
}

.reviews-content {
	p:first-of-type {
		@include font-size(22px);
		line-height:1.5;
	}
}
.reviews-byline {
	@extend .contentSlider-byline;
	@include font-size(14px);
	margin-top:$spacingSm;
}

// Specific changes for listing
.reviewsListing {
	padding: $spacingBase 0 $spacingLg;
	p.reviews-rating {margin:0;}
	ul {
		@extend .list--bare;
		padding-top: 10px;
		li {
			@include mq(med) {
				.reviewsListing-review {
					margin:0 0 $spacingLg 0;
					padding:0 $spacingBase*.75;
					border-left:5px solid $colorBrandPrimary;
				}
			}
		}
	}		
}