/* FEATURE BLOCK
========================================================================== */
.video {
    @extend .h-hard;
}
.video-inner {
    @extend .spotlight-inner;
    padding:0;
}
.video-content {
    @extend .spotlight-content;
    &:before {display:none;}
}
.video-embed {
    @include flex(0 0 auto);
    iframe {
        width: 100%;
        min-height: 100%;
    }
}
.video-heading {
    @extend .spotlight-heading;
}

@include mq(med) {
    .video-embed {
        width: 50%;
    }
    .video--imgRight {
        .video-content {
            @include order(1);
            margin-left:auto;
            margin-right:0; 
            &:before {
                left:auto;
                right:-70px;
            }                       
        }
        .video-embed {
            @include order(2);
        }
    }
}
