/* WEBKIT SCROLLBAR
========================================================================== */

/*
* Webkit versions of chrome have removed the scrollbar. This adds it back for usability purposes
* so users can identify scrolling content
*/

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    border-radius: 4px;
    background: $colorGreyLighter;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: $colorGrey;
}
::-webkit-scrollbar-thumb:hover {
    background: rgba($colorBlack, .3);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: rgba($colorBlack, .3);
}
