/* BACK TO TOP
========================================================================== */

/*Anchor to top*/
.backToTop-anchor {
    position:absolute;
    top:0;
}
a.backToTop {
    @include font-size(14px);
    line-height: 2.25;
    display: inline-block;
    padding: $spacingXSm*.25 $spacingSm;
    border-radius: 40px;
    @include order(1);
    margin:$spacingSm 0;
    color:$colorWhite;
    background:$colorBrandPrimary;    
    text-decoration:none;
    z-index:1;
    &:hover {color:$colorWhite;background:$colorBrandPrimaryDark;text-decoration:none;}

    @include mq(med) {
        margin:0;

        /** Uncomment for fixed position **/
        position:fixed;
        bottom:10px;
        right:10px;
        display:none;
        &.backToTop--scrolled {
            display:block;
        }        
    }

    &:before {
        @extend %iconStyle;
        @extend .icon-caret-up;
    }

    @include mq(med) {
        float: right;
        margin:2px 0 2px 5px;
    }
}
