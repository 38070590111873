/* LIST - CONTENT
========================================================================== */

.listContent {
	.blogItem-infoList {
		line-height:1;
		margin-bottom:$spacingXSm;
	}
}
.listContent-heading {
	text-align:center;
}
.listContentItem {
	@extend %noPhatBottom;
	@extend %clearfix;
	border-bottom:1px solid $colorBorder;
	padding-bottom:$spacingBase;
	margin-bottom:$spacingBase;
}
.listContentItem-body {
	p {
		position:relative;
		padding-left:$spacingBase;
		&:before {
			content:'';
			height:100%;
			border-left:5px solid $colorBrandPrimary;
			position:absolute;
			left:0;
			margin-top:$spacingXSm;
		}
	}
}
.listContentItem-heading {
	@include font-size(28px);
	line-height:1.2;	
	margin-bottom:$spacingXSm;
	font-family:$fontHighlight;
	a {text-decoration:none;color:$colorText;}
}
.listContentItem-figure {
	text-align:left;
	margin-bottom:$spacingSm;
}
 @include mq(med) {
 	.listContentItem-heading {
 		@include font-size(32px);
 	}
 	.listContentItem-body {
 		overflow:hidden;
 	}
 	.listContentItem-figure {
 		float:right;
 		margin-left: $spacingBase;
 		margin-bottom:0;
 		max-width: 35%;
 		text-align:center;
 	}
 }
