/* PARAGRAPHS
========================================================================== */ 

/*
 * The `.lede` class is used to make the introductory text (usually a paragraph)
 * of a document slightly larger.
 */

.lede,
.lead{
    @include font-size($fontSizeBase * 1.25);
    line-height:1.7;
    position:relative;
    font-family:$fontDefault;
}

.lead--border {
    border-left:5px solid $colorBrandPrimary;
    padding-left:$spacingBase;
    &.borderLg {
        border-left-width:10px;
  
    }
}
.lead--borderGrey {
    @extend .lead--border;
    border-left-color:$colorGreyLightest;
}
