/* QUOTES
========================================================================== */

@include mq(med) {
    blockquote {
        &.blockquote--right {
            float: right;
            text-align:left;

            max-width: 35%;
            margin: .35em 0 $spacingBase $spacingBase;
        }
        &.blockquote--left {
            float: left;
            text-align:left;

            max-width: 35%;
            margin: .35em $spacingBase $spacingBase 0;
        }
    }
}
