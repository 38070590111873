/* LOCATION
========================================================================== */
.location {
    @extend %flexbox;
    @include flex-direction(column);
    max-width: $startXXLg;
    margin-right: auto;
    margin-left: auto;
    // display:block !important;
    width:100%;
    align-items: center;
}
.location--inner {
    @extend %flexbox;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(space-between);
    max-width: $startXXLg;
    margin-right: auto;
    margin-left: auto;
}
.location-content {
    padding:$spacingBase;
    a {text-decoration:none;}
    .btn {margin-bottom:$spacingSm;}
    .location-content-inner {
        @extend %noPhatBottom;
        @extend %flexbox;
        @include align-items(flex-start);
        @include flex-direction(column);
        @include justify-content(space-between);
        min-width: 0;
        p {
            margin-bottom: $spacingSm;
            &:last-of-type {
                margin-top:$spacingBase;
            }
        }
        //stupid IE11 hack
        * {
            max-width: 100%;
        }
    }  
    @include mq(xlg) {
        padding:$spacingLg;
    }  
}
.location-content--info {
    width:100%;
}
.location-content--hours {
    @include font-size(14px);
    width:100%;
    .location-content--time {
        @extend %flexbox;
        @include justify-content(space-between);
        span:first-of-type {
            margin-right:$spacingBase;
        }
    }
}

@include mq(med) {
    .location--inner {
        @include flex-direction(row);    
    }   
}
@include mq(lg) {
    .location-content .location-content-inner {
        @include flex-direction(row);
        @include justify-content(space-between);
    }
    .location-content--info {
        text-align:left;
    }
    .location-content--hours {
        padding-left:$spacingSm;
    }
}
.location-content p,
.location-heading,
.location-fakeSelectWrap,
.location-inlineLabel input {
    min-width: 50%;
    margin-bottom: $spacingSm;
}
.location-map {
    @include flex(0 0 auto);
    width:100%;
    position: relative;
    display: block;

    iframe {
        width: 100%;
        min-height:100%;
    }
    .location-map-buttons, .btn.on-map {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
.location-heading {
    @include font-size($h3Size);
    line-height:1.2;
    margin-bottom:$spacingSm;
}
.location-subheading {
    @include font-size($h6Size);
    margin-bottom:$spacingSm;
    text-align:left;
    text-transform:initial;
    font-weight:normal;
    border-bottom:1px dotted $colorGreyLight;
}
.location-phone,
.location-fax {
    &:before {
        @extend %iconStyle;
    }
}
.location-phone {
    @extend .icon-mobile;
}
.location-fax {
    @extend .icon-printer;
}

@include mq(med) {
    .location {
        @include flex-direction(row);
    }
    .location-content {
        //the 0% is another IE11 hack
        @include flex(1 1 50%);
        min-width: 0;
        text-align: center;
    }
    .location-map {
        @include flex(1 1 50%);
        max-width:50%;
        min-width: 0;
    }
    .location--mapRight {
        .location-content-inner {
            @include order(1);
        }
        .location-map {
            @include order(2);
        }
    }
}
