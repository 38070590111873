/*  OVERLINE
========================================================================== */

/*
 * Add overlines.
 */

.h-overline {
    border-top: 1px solid $colorBorder !important;
}

.h-border {
	border:1px solid $colorBorder;
}
.h-borderTop--accent {
	border-top-color:$colorBrandPrimary;
	border-top-width:3px;
}


