/* MQ-VISIBILITY
========================================================================== */
/*
* This is a work in progress for toggling visibility based on media queries
* check out it's usage in base/_helpers.scss
*/
  @mixin hidden($mq) {
    @include mq($mq) {
      display: none !important;
    }
  }
  // Mixin for visible
  @mixin visible($mq) {
    @include mq($mq) {
      display: inherit !important;
    }
  }
