/* CONTENT ROTATOR
========================================================================== */
.contentSlider {
	.contained {
		max-width:800px
	}
}
.contentSlider-content {
	padding-bottom:$spacingLg;
	margin-bottom:0;
	.slick-track {
		display:flex;
	}
}
// Needs differentiator in order to override slick slide
.slick-slide.contentSlider-item {
	@include responsive-font(5vw, 16px, 32px, 24px);
	text-align:center;
	display:flex;
	height:auto;
	flex-direction:column;
	justify-content:center;
}
.contentSlider-byline {
	@include font-size(14px);
	margin:0;
	&:before {
		content:'-' '\00a0';
	}
}
