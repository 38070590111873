/* BANNER & VIDEO BANNER
========================================================================== */
// SEE VIDEO BANNER BELOW 

.banner{
    // @extend %flexbox;
    // @include align-items(center);
    text-align: center;
    display:block;
    position:relative;
    padding: $spacingLg $spacingBase;
    @include mq(lg) {
        padding:$spacingXLg $spacingLg;
    }    
    &.banner--fixedBackground {
        background-attachment: fixed;
    }
    &[class*='banner--textBackground'] {
        .bannerCopy {
            padding: $spacingBase;
        }
    }  
    &[style*='background-image'] {
        background-color:$colorBrandPrimary;
        background-position: center center;
        background-size:cover;
        position:relative;        
        .banner-contained {
            @include mq(lg) {
                position: absolute;
                top: $spacingBase;
                right: $spacingBase;
                bottom: $spacingBase;
                left: $spacingBase;
            }     

        } 
        @include mq(lg) {
            min-height:350px;
        }  
        @include mq(xlg) {
            min-height:500px;
            background-size: cover;                      
        }               
    }    
}

.banner-contained {
    @extend %flexbox;
    @include flex(1 1 0);
    //for IE
    width: 100%;
    @include justify-content(center);    
}
.bannerCopy {
    @extend %noPhatBottom;
    @include font-size(18px);
    @include align-self(flex-end);    
    text-align: center;
    line-height: 1.5;
    z-index:2;
    // color:$colorText;
    p {
        max-width:700px;
        margin-left:auto;
        margin-right:auto;
        &:only-child {
            @include font-size(26px);
            line-height:1.4;
            font-weight:normal;
            max-width:900px
        }        
    }
    // @include mq(med) {
    //     display: inline-block;
    // }
}
.banner {
    @include mq(lg) {
        &[style*='background-image'] {
            .bannerCopy {
                max-width:48%;
            }
        } 
        &.banner--centerCenter .bannerCopy, &.banner--centerCenter[style*='background-image'] .bannerCopy {
            max-width:85%;
        }
    }
}
.bannerCopy-heading {
    @extend .h-pushSmBottom;
    @include responsive-font(3vw, 30px, 36px, 34px);
    line-height:1.2;
    text-transform:initial;
    font-family:$fontHighlight;
    span {
        font-weight:normal;
    }

}
.banner--textLight {
    color: $colorWhite;
    .bannerCopy-heading {
        color:$colorWhite;
    }
}
.banner--textDark {
    color: $colorText;
    .bannerCopy-heading {
        color:$colorText;
    }
}
.banner--textBackgroundLight {
    .bannerCopy {
        color: $colorText !important;
        background: rgba($colorWhite, .95);
    }
    .bannerCopy-heading {
        color:$colorText !important;
    }
}
.banner--textBackgroundDark {
    color: $colorWhite;
    .bannerCopy {
        color: $colorWhite;
        background:$colorBrandPrimary;
    }
    .bannerCopy-heading {
        color:$colorWhite;
    }
}

// Video has banner alignments managed alongside Slider Main
.bannerVideo {
    // Note: this is extended and all specific alterations for video are below
    @extend .banner;
    padding:0 !important;
    background:none;
    overflow:hidden;          
    video {
        position:relative;
        top:0;
        bottom:0;
        right:0;
        left:0;  
        margin:auto;      
        width:100%;  
        // height:100%;
        z-index:-1;     
    }
    .banner-contained {
        width:100%;
        @include flex-wrap(wrap);
        @include mq(lg) {
            margin:0;
            position: absolute;
            top: $spacingBase;
            right: $spacingBase;
            bottom: $spacingBase;
            left: $spacingBase;
        }     


    }
    @include mq(lg){
        min-height:350px;
        // Note: video banners do not contain content similar to sliders
        .banner-contained {
            padding:$spacingSm $spacingBase;            
            display:flex;
            align-self:stretch;
            top:0;
            right:0;
            bottom:0;
            left:0;
        }            
        video {
            position:absolute;
        }          
        .bannerCopy {
            width:50%;
            max-width:700px;
        } 
    }   
    @include mq(xlg) {
        min-height:550px;

    }          
    @media (max-aspect-ratio: 16/9) {

        video {
            // width: 300%;
            // left: -100%;
            // height: 300%;
            // top: -100%;             

        }
    }     
}

.ag-banner-image.lazy {
    background-image: none !important;
}

// background attachment fixed is an issue for iOS, so remove for these devices
@supports (-webkit-overflow-scrolling: touch) {
    .banner.banner--fixedBackground {
        background-attachment: scroll;
    }
}