/* LISTS
========================================================================== */ 

/*
* Have a numbered `ul` without the semantics implied by using an `ol`. */
.list--numbered, ol {
	list-style-type: decimal;
}
.list--horizontal {
	margin-left:0;
	padding: 0;
	list-style-type: none;
	li {
		display: inline-block;
		//bottom margin is in case of wrapping
		margin: 0 $spacingSm $spacingXSm 0;
	}
	&.list--separated {
		li {
			padding-left: $spacingSm;
			position: relative;
			&:before {
				content: '·';
				position: absolute;
				top: 0;
				left: -4px;
			}
			&:first-child {
				padding-left:0;
				&:before {
					display:none;
				}
			}
		}
	}
	@include mq(med) {
		li {
			margin-bottom:0;
		}
	}
}
.list--bare {
	list-style: none;
	padding: 0;
	margin-left:0;
}
.list--block {
	list-style: none;
	padding: 0;
	margin-left:0;
	a {text-decoration:none;}
	a:hover {text-decoration:underline;}
	> li {
		padding:$spacingXSm;
		border-bottom:1px solid $colorBorder;
		&:first-child {
			border-top:1px solid $colorBorder;
		}
	}
}

.list--columns, .listColumns {	
	padding:0 0 0 $spacingSm*1.25;
	a {text-decoration:none;}
	a:hover {text-decoration:underline;}	
	@include mq(sm) {
        -moz-column-count: 2;
	    -moz-column-gap: $spacingLg;
	    -webkit-column-count: 2;
	    -webkit-column-gap: $spacingLg;
	    column-count: 2;
	    column-gap: $spacingLg;		
	}        
    @include mq(med) {  	
	    &.three {
		    -moz-column-count: 3;
		    -webkit-column-count: 3;
		    column-count: 3;
	    }      
	    &.four {
		    -moz-column-count: 4;
		    -webkit-column-count: 4;
		    column-count: 4;
	    }
    }   
}