/* BACL LINK
========================================================================== */

.backLink {
	@include font-size(13px);
	@extend .icon-arrow-left;
	color:inherit;
	text-decoration:none;
	&:before {
		@extend %iconStyle;
	}
}
