/* NAV MAIN
========================================================================== */

.navMain {
   //display:none;
   @include align-self(stretch);
   background:$colorBrandPrimary;
   @include mq(lg) {
      @include flexbox;
   }
   ul {
      margin-bottom: 0;
      padding: 0;
      list-style: none;
   }
   > ul {
      @extend %flexbox;
      @extend %contained;
      > li {
         @include font-size(16px);
         @extend %flexbox;
         @include align-items(flex-end);
         @include flex(1 1 auto);
         position: relative;
         > span, > a {
            position: relative;
            z-index: 1;

            display: block;
            overflow: hidden;

            padding: $spacingSm;
            margin:0 $spacingXSm*1.1;
            cursor: pointer;
            text-align: center;
            color: $colorWhite;
            font-weight:normal;
            transition:all .5s ease-in-out;
         }
         &.btn {
            padding:0;
            margin:$spacingBase $spacingSm $spacingBase $spacingXSm;
            a {
               background:$colorAccent;
            }
         }
         > ul {
            @include font-size(15px);
            line-height:1.2;
            transition:all .2s ease-in-out;
            @include flex-direction(column);
            position: absolute;
            z-index: 10;
            top: 100%;
            right: 0;

            min-width: 250px;
            padding: $spacingSm;

            transform: scale(1,0);
            transform-origin: top;

            background: $colorGreyLightest;
            border-bottom:1px solid $colorGreyLight;
            > li a {
               transition:all .25s ease-in-out;
               display: block;
               padding: 4px $spacingXSm;
               color: $colorBlack;
               font-weight:normal;
               &:hover {
                  color:$colorLinksHover;
                  text-decoration:underline;

               }
            }

            // Third Level Links
            ul {
               margin-left:$spacingSm*1.5;
               @include font-size(14px);
               li a {
                  padding:0;
                  font-weight:300;
               }

            }
            // &.pullLeft {
            //    right: 0;
            //    left: auto;
            // }
         }
         &:hover {
            > span, > a {
               background:$colorBrandPrimaryDarker;
               color: $colorWhite;
            }
            > ul {
               -ms-transform: scale(1,1);
               -webkit-transform: scale(1,1);
               transform: scale(1,1);
            }
         }
         &.selected {
            > span, > a {
               color: $colorWhite;
               background: $colorBrandSecondary;
            }
         }
         &.is-mega {
            position: static;
            > ul {
               @extend %flexbox;
               @include flex-direction(row);
               @include justify-content(center);
               left: 0;
               padding:$spacingBase $spacingBase $spacingLg;
               border-bottom:1px solid $colorGreyLight;
               > li {
                  @include flex(1 1 0);
                  //for IE11
                  width: 100%;
                  max-width: 250px;
                  padding-left: $spacingSm;
                  // &:first-child {
                  //    padding: 0;
                  // }
                  > a {
                     margin-bottom: $spacingXSm;
                     border-bottom:1px solid $colorGreyLight;
                     padding-left:0;
                  }
               }
               ul {
                  @include font-size(15px);
                  margin:0;
               }
            }
         }
         &.is-columns {
            > ul {
               @extend .list--columns;
               padding:$spacingBase $spacingBase $spacingLg !important;    
               // making this transparent effects ADA contrast            
               background:$colorGreyLightest;
               display:block;
               max-width:$maxWidth;
               margin:auto;
               border-bottom:none;
               > li {
                  a {border:none;display:inline-block;}
                  ul, ul ul {
                     display:none;
                  }
               }
               &:before {
                  content:'';
                  background:$colorGreyLightest;
                  border-bottom:1px solid $colorGreyLight;
                  left:-100%;
                  right:-100%;
                  top:0;
                  bottom:0;
                  position:absolute;
                  z-index:-1;
               }           
            }
            @include mq(lg) { 
   
                &.three > ul {
                   -moz-column-count: 3;
                   -webkit-column-count: 3;
                   column-count: 3;
                }      
                &.four > ul {
                   -moz-column-count: 4;
                   -webkit-column-count: 4;
                   column-count: 4;
                }
             }             
         }   
         ul li.is-wide {
            max-width:300px
         }       
         &.hasChildren {
            > a {
               &:after {
                  display: inline-block;

                  width: 0;
                  height: 0;
                  margin-left: $spacingXSm;

                  content: '';
                  vertical-align: middle;

                  border-top: 4px solid;
                  border-right: 4px solid transparent;
                  border-left: 4px solid transparent;
               }
            }
         }
      }
   }
}

@include mq(lg) {
   .namMain {
      >ul >li {
         @include font-size(18px);
      }
   }
}

//Hide menu items when the nav becomes hidden
// body.js-headerFixed--up {
//    .navMain li.hasChildren > ul {
//       transform: scale(1,0) !important;
//    }
// }
