/* INFO LIST
========================================================================== */

.infoList {
    @include font-size(14px);
    padding:0 $spacingSm;
    list-style: none;
    border-left: 3px solid $colorGreyLighter;
    a {text-decoration: none }
    @include mq(med) {
        padding: 0;
        border-width: 0;
        li {
            position: relative;

            display: inline-block;

            margin-right: $spacingSm;
            padding-right: $spacingSm;

            &:after {
                position: absolute;
                top: 0;
                right: -4px;

                content: '\2022';
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }
    }

}