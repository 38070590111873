/* FEATURE BLOCK
========================================================================== */

.featureBlock {
    img {margin-bottom:$spacingSm;}
    a {text-decoration:none}
    &.section--contrastDark {
        padding:$spacingLg 0;
    }
}

.featureBlock-heading {
    @include responsive-font(3vw, $h3Size, $h2Size, $h3Size);
    font-family:$fontHighlight;
    line-height:1.3;   
    text-align: center;
    max-width:870px;
    margin-left:auto;
    margin-right:auto;
    text-transform:initial;
    margin-bottom:$spacingBase*1.5;
    span {color:$colorBrandPrimary;}
}
.featureBlock-figure {
    @extend %noPhatBottom;
    position:relative;
    height:100%;
    margin-bottom:$spacingBase;
    @include mq(lg) {
        margin-bottom:0
    }    
}
.featureBlock-figcaption {
    font-style:initial;
    text-align:center;    
    padding-bottom: 0;
    border-bottom-width: 0;
    p {
        margin-bottom: $spacingSm;
        line-height:1.4;
        &:last-child {
            margin:0;
        }
    }
    ul, ul li {text-align:left;}
    .btn {}
    &:only-child {text-align:left;}
}
.featureBlock-subHeading, .featureBlock-mainCopy h2, .featureBlock-mainCopy h3, .featureBlock-mainCopy h4{
    @include font-size(22px);
    line-height:1.3;
    margin-bottom: $spacingXSm;
    font-weight:normal;
    text-transform:initial;
    span {color:$colorBrandPrimary;}    
    a {
        &:hover {
            text-decoration:underline;
        }
    }
}

.featureBlock-single {
    .featureBlock-figure {margin:0;}
}
.featureBlock--three, .featureBlock--four {
    text-align:center;
}
.featureBlock--three {
    @include mq(667px) {
        .lg-8 {
            @include flex-basis(33.23333%);
            max-width:33.23333% !important;
        }
    }
}
.featureBlock-mainFeature {
    @extend %flexbox;
    @include flex-direction(column);

    @include mq(lg) {
        @include flex-direction(row);
        @include align-items(center);
    }
}
.featureBlock-mainCopy {
    //overflow:hidden;
}
.featureBlock-mainImage {
    @include mq(med) {
        padding-right: $spacingBase;
        flex-shrink:0
    }
}
.featureBlock-mainFeature--imageRight {
    .featureBlock-mainImage {
        @include mq(med) {
            @include order(2);
            float: right;
            padding-right: 0;
            padding-left: $spacingBase;
        }
    }
}

@include mq(lg) {
    .featureBlock--four, .featureBlock--three {
        .featureBlock-figure {
            @include font-size(16px); 
            margin-bottom: 0;
        }
    }
}
