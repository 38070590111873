/* MAIN HEADER
========================================================================== */

.logoMain {
    padding: $spacingBase*.9;

    text-align: center;
    img {
        transition:all 0.2s ease-in-out;
        //adjust this max-height to suit the client logo
        max-height: 45px;
        //shrink the logo to an appropriate size when the header is fixed on scroll
        body[class*='js-headerFixed'] & {
            max-height:35px;
        }
    }
    @include mq(lg) {
        @include flexbox;
        @include align-items(center);
        //max-width: 20%;

        //we need to put a width on the a tag so the logo has something to be max-width:100% OF
        > a {
            display: inline-block;

            width: 100%;
        }
        img {max-height:60px;}
    }

}

.logoBlurb {
    display:none;
    font-weight:bold;
    max-width:250px;
    line-height:1.2;
    border-left:1px solid $colorGreyLight;
    padding:0 0 0 $spacingSm;
    color:$colorGreyDark;
    @include mq(800px) {
        display:inline-block;
    }
}
