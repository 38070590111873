.dz-label {
    margin-bottom: $spacingBase;
}

.dropzone {
    border: 1px solid #e5e5e5 !important;
    min-height: 125px;

    .file-row {
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: $spacingBase;
        margin-bottom: $spacingBase;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        div {
            padding-bottom: 0;


        }
    }
    

}
button.btn.fileinput-button.dz-clickable {
    margin-top: 13px;
}

@include mq(med) {
    .dropzone {
        padding: 13px 20px 0px 20px !important;

        .file-row {
            padding-bottom: 0;
            margin-bottom: 13px;

            div {
                display: inline-flex;
                width: calc(98% / 4);
                max-height: 45px;

                &:nth-child(2) {
                    width: 10%;
                }

                &:nth-child(3) {
                    width: 15%;
                }
            }
        }


    }

}