/* RELATED LINKS
========================================================================== */
.relatedLinks {
    background:$colorGreyLightest;
    padding:$spacingBase*1.5 $spacingBase;
    // background: linear-gradient(0deg, rgba($colorWhite,1) 0%, rgba($colorGreyLight,.7) 100%);
    @include mq(xlg){
        padding:$spacingLg 0;
    }
}    

.relatedLinks-inner {
    @extend .contained;
    ul {
        display:flex;
        flex-wrap:wrap;
        list-style-type:none;
        padding:0;
        margin:0 -$spacingXSm;
        width:100%;
        li {
        	@include font-size(15px);
        	line-height:1.4;
        	align-self:stretch;
        	width:48%;
        	padding:$spacingSm 0;
        	margin:0 1%;
        	border-bottom:1px dotted $colorGrey;
            a {
                line-height:1.2;
                text-decoration:none;
                &:hover {text-decoration:underline;}
            }
        	&:before {
        		font-family:$fontIcon;
        		margin:0 0 0 -18px;
        		color:$colorLinks;
        	}
        }
    }
}

.relatedLinks-heading {
	@include font-size(20px);
	color:$colorGreyDarkest;
    margin-bottom:$spacingXSm;
}
@include mq(med) {
    .relatedLinks-inner {
        ul li {
            width:29%;
        }
    }
}
@include mq(lg) {
	.relatedLinks-heading {
		max-width:175px;
		margin-right:$spacingLg;
	}
	.relatedLinks-inner {
	 	display:flex;
	 	justify-content:space-between;
        align-items:flex-start;
	 	ul li {
	 		width:29%;
	 		margin:0 2%;
	 	}
	}
}
