/* MAIN FOOTER
========================================================================== */

.footerMain {
    @include flex(0 0 auto);
    @include font-size(15px);
    background: $colorGreyLightest;    
    color:$colorText;
    border-top:5px solid darken($colorGreyLighter,5%);
    a {
        color:$colorText;
        text-decoration:none;
        &:hover {
            color:$colorLinksHover;
            text-decoration:underline;
        }
    }
    .ag {
        .agTitle {
            display:block;
            margin:$spacingXSm 0 $spacingBase;
            font-size:18px;
            line-height:1.2;            
            a {
                text-decoration:none;
                font-weight:bold;            
                color:$colorGreyDarker;
                text-transform:uppercase;            
            }
        }
        img {
            max-width:60px;
            float:left;
            margin-right:$spacingBase;
        }
        .agContent {
            overflow:hidden;
        }
    }
}
.footermain-top {
    padding:$spacingBase $spacingBase 0;
    p {margin:0;}
}
.footermain-topInner {
    @extend %contained;
}
.footerMain-heading {
    display:block;
    @include font-size($h5Size);
    margin:0 0 $spacingSm 0;
    text-transform:initial;
    font-family:$fontDefault;
    font-weight:normal;
    color:$colorGreyDarker;
}
.footerMain-topNav {  
    ul {
        padding: 0;
        list-style: none;
        li.btn {
            margin-bottom:$spacingSm;
        }         
    }
    &.footerMain-contact {
        [class^='icon-'] {
            position:absolute;
            left:-$spacingBase;
            color:$colorGreyDarker;
        } 
        li {
            padding: 0 0 $spacingXSm 0;
            margin-left:$spacingBase;
            position:relative;
        }        
    }
    &.footerMain-hours {
        span {display:inline-block;min-width:70px;}
    }
}
.footerMain-topNav--socialNav ul li {
    display: inline-block;
    &:last-child {
        margin-right: 0;
    }
    a {
        display: block;
        padding: $spacingXSm 2px;
        color: $colorLinks;
    }
}
.footerMainSocialLink {
    @include font-size(28px);
    line-height:1;
    padding: 0;
    list-style: none;
    text-decoration: none;
    &:hover:before {text-decoration:none;}
    &:before {
        @extend %iconStyle;
        margin: 0;
        font-weight:normal
    }
    &--facebook {
        @extend .icon-facebook-circle;
    }
    &--twitter {
        @extend .icon-twitter-circle;
    }
    &--youTube {
        @extend .icon-youtube-circle;
    }
    &--linkedin {
        @extend .icon-linkedin-circle;
    }
    &--rss {
        @extend .icon-rss-circle;
    }
    &--googlePlus {
        @extend .icon-googleplus-circle;
    }
    &--skype {
        @extend .icon-skype-circle;
    }
    &--flickr {
        @extend .icon-flickr-circle;
    }
    &--instagram {
        @extend .icon-instagram-circle;
    }
    &--pinterest {
        @extend .icon-pinterest-circle;
    }
}
.footermain-bottom {
    @extend %flexbox;      
    @extend %guttered;
    padding-top: $spacingSm;
    padding-bottom: $spacingSm;
    flex-direction:column; 
    justify-content:flex-start;    

}
.footermain-bottomInner, .F-Bp {
    @include font-size(13px);
    line-height:1.4;
    order:2;
    @include mq(med) {
        margin:0 auto;
        width:100%;
        max-width:$maxWidth;
    }
}
//Footer Main, Bottom paragraph
.F-Bp {order:1;}

.footermain-bottomInner {
    @extend .sideToSideLg;
    @include mq(med) {
        address {margin:0;}
    }
}
.footerMain-bottomInfo {}
.footerMain-bottomNav ul {
    @extend %flexbox;
    padding: 0;
    list-style: none;

    li {
        margin: $spacingXSm $spacingSm;
        &:first-child {margin-left:0;}
    }
    a {
        color: inherit;
        text-decoration:underline;
        &:hover, &:focus {
            color:$colorLinksHover;
        }        
    }
}
