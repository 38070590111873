/* PAGINATION
========================================================================== */

.pagination {
  padding:$spacingSm 0;
  margin-bottom:0;
  list-style:none;
  font-size:0;
  text-align:center;
  a {text-decoration:none}
  li {
    @include font-size(15px);
    padding:0;
    display:inline-block;
    text-align:center;    
    // border-left:1px solid $colorWhite;

    &:first-child {
      border-width:0;
    }

    &:before {
      display:none;
    }

    a {
      @include font-size(15px);      
      display:block;
      padding:0 .5em;
    }
    // &.pagination-prev, &.pagination-next, .prev, .next {
    // }
    &.pagination-prev a, &.pagination-next a, .prev, .next {
      background-color: $colorGrey;
       border-radius:200px;
      color: $colorWhite;
      padding:0.2em 0.4em;
      line-height:18px;
      font-size:14px;
      text-transform:uppercase;
      &:before {
        font-family: Fontello;
        font-size:14px;
        display:inline-block;
        // height:1em;
        // width:1em;
      }
    }

    &:hover, &.pagination-current {
      a {
        color:$colorGreyDarker;
        text-decoration:none;
      }
    }
    &.pagination-prev a, .prev {
      &:before {
        content: '\e989';
        margin-right:5px;
      }
      &:hover {
        color:$colorWhite;
      }
    }

    &.pagination-next a, .next {
      &:after {
        content: '\e98a';
        poisition:absolute;
        right:0;
        margin-left:5px;
        color:$colorWhite;
        font-family: Fontello;
        font-size:14px;
        display:inline-block;        
      }
      &:hover {
        color:$colorWhite;
        background:$colorLinksHover;
      }
    }
    &.is-disabled, &.is-disabled a, .inactive {
      pointer-events:none;
      color:$colorWhite;
    }

    &.is-disabled a , .inactive {
      background-color:$colorGreyLighter;
    }
  }
}

.pageNumbers {
    @extend %flexbox;
    @include justify-content(center);
    a,
    span {
        @include font-size(15px);
        padding: 0 $spacingXSm;
        color:$colorText;
        text-align: center;
    }
    .current {
        color: $colorLinks;
    }
    .prev, .next {
      border-radius:200px;
      color: $colorLinks;
      padding:0.2em $spacingSm;
      line-height:18px;
      font-size:15px;
      text-transform:uppercase;
      &:before, &:after {
        font-family: 'fontello';
        font-size:14px;
        display:inline-block;
        // height:1em;
        // width:1em;
      }   
    }
    .prev {
      &:before {
        content: '\e989';
        margin-right:5px;
      }    
    }

    .next {
      &:after {
        content: '\e98a';
        margin-left:5px;
        font-family: 'fontello';
        font-size:14px;
        display:inline-block;        
      }
      &:hover {
        color:$colorLinksHover;
      }
    } 
    .inactive {
      pointer-events: none;
      color: $colorGrey;
    }  
    @include mq(lg) {
      @include justify-content(flex-end);
    }  
}


