/* BUG
========================================================================== */

/*
* Indicator bug. Number of messages, alerts, etc...
*/
.toolBar {
    position:fixed;
    right:0;
    left:0;
    bottom:0;
    padding:0; 
    visibility: hidden;
    ul {
        @extend %flexbox;
        @extend .list--bare;
        justify-content:center;
        margin:0;
        li {
            font-weight:normal;
            background:rgba($colorBlack,.95);          
            border-right:1px solid $colorGreyLighter;
            text-align:center;
            padding:$spacingXSm $spacingSm*1.5;    
            &:first-child {border-radius:8px 0 0 0}
            &:last-child {border-radius:0 8px 0 0}                    
            a {
                color:$colorWhite;
            }
            &:last-child {border:0;}
            i {
                margin: 0 $spacingXSm 0 0;                
            }
        }
    }
    @include mq(med) {
        left:auto;
        bottom:$spacingLg*1.1;
        ul {
            transition:all 0.2 ease-in-out;
            flex-direction:column;
            li {
                border:none;
                border-bottom:1px solid $colorBorder;
                padding:$spacingSm;
                &:last-child {border-radius:0 0 0 8px}                  
                i {@include font-size(22px)}
                &:hover {
                    background:$colorBrandPrimaryDarkest;
                    &:before, a {color:$colorWhite;}
                }
            }
        }
    }
}
