/* SOCIAL SHARE
========================================================================== */

/*
* Stylin for sharethis, addthis, whatevs.
*/

// .socialShare {}
.socialShare-inner {
	@extend %contained;
	display:flex;
	padding-top:$spacingSm;
	padding-bottom:$spacingSm;
	color:$colorGreyDark;
	@include mq(med) {
		justify-content:flex-end;
	}
}
.socialShare-heading {
	@include font-size(18px);
	margin:0 $spacingBase 0 0;
}
.socialShare-icons {
	> span {
		margin-left:$spacingSm;
		&:first-child {
			margin:0;
		}
	}
}
.stButton {
    display: inline-block;
	margin:0 !important;
	vertical-align:middle !important;
	font-size:16px !important;
	line-height:16px !important;
	color:inherit !important;
    text-decoration: none;
    cursor: pointer;
}
.stButton .stLarge {
	width:auto !important;
	height:auto !important;

	background:none !important;

	//color:$colorGrey;
	&:before {
		@extend %iconStyle;

		margin:0;

		font-size:100%;
	}
}
.st_twitter_large .stLarge {
	@extend .icon-twitter;
}
.st_facebook_large .stLarge {
	@extend .icon-facebook;
}
.st_email_large .stLarge {
	@extend .icon-mail;
}
.st_print_large .stLarge {
	@extend .icon-printer;
}
.st_sharethis_large .stLarge {
	@extend .icon-share;
}
