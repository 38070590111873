/*SEARCH ITEM
=======================================================================================*/

.searchItem {
	@extend .blogItem;
}
.searchItem-heading {
	@extend .blogItem-heading;
}
.searchItem-figure {
	@extend .blogItem-figure;
}
 @include mq(med) {
 	.searchItem-body {
 		overflow:hidden;
 	}
 	.searchItem-figure {
 		float:right;
 		margin-left: $spacingBase;
 		margin-bottom:0;
 		max-width: 35%;
 		text-align:center;
 	}
 }