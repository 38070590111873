/* SLIDER MAIN
========================================================================== */

.sliderMain {overflow:hidden;}
.sliderMain.slick-dotted {margin-bottom: 0}

.sliderMain-slide {
    position: relative;
    a {
        color: $colorText;
        text-decoration:none;
    }
}
.sliderMainImage {
    height: 40vmin;
    min-height: 250px;

    // background-position: center center !important;
    background-size: cover;
    background-repeat:no-repeat;
    @include mq(med) {min-height: 400px;}
    @include mq(lg) {min-height: 500px;}        
}
.sliderMainContent {    
    @include mq(lg) {
        position: absolute;
        top: $spacingBase;
        right: $spacingBase;
        bottom: $spacingBase;
        left: $spacingBase;        
    }
}
.sliderMainContent-inner {
    @extend %flexbox;

    position: relative;

    height: 100%;
    padding: $spacingBase $spacingBase $spacingLg $spacingBase;
    text-align: center;
    @include mq(lg) {
        padding-top: 0;
        padding-bottom: 0;
    }
}
.sliderMainContent-copy {
    @extend %noPhatBottom;
    line-height: 1.4;
    position:relative;

    @include mq(med-only) {
        max-width:650px;
        margin:auto;
    }
    @include mq(lg) {
        max-width: 55%;
        > * {
            position:relative;
            z-index:2
        }        
        [class*='sliderMain-slide--textBackground'] & {
            padding: $spacingSm;
        }
    }
    @include mq(xlg) {
        max-width: 48%;
        @include font-size(18px);        
    }    
}
.sliderMainContent-heading {
    @extend .h-pushXSmBottom;
    @include responsive-font(6vw, 24px, 44px, 28px);
    line-height: 1.2;
    color:$colorBrandPrimary;
    font-family:$fontHighlight;
    font-weight:bold;
    span{font-weight:normal}
    @include mq(lg-only) {
        @include responsive-font(4vw, 24px, 34px, 28px);
    }
}
//classes applied to .sliderMain-slide that position the copy
@include mq(lg) {
    //classes applied to .sliderMain-slide that position the copy
    .sliderMain-slide--leftTop, .banner--leftTop {
        .sliderMainContent-inner, .banner-contained {
            @include align-items(flex-start);
            .bannerCopy{@include align-self(flex-start);text-align: left;margin-right:auto;}
            text-align: left;
        }
        hr {
            margin-right: auto;
            margin-left: 0;
        }
    }
    .sliderMain-slide--centerTop, .banner--centerTop {
        .sliderMainContent-inner, .banner-contained {
            @include justify-content(center);
            @include align-items(flex-start);
            .bannerCopy{@include align-self(flex-start);}           
        }
    }
    .sliderMain-slide--rightTop, .banner--rightTop {
        .sliderMainContent-inner, .banner-contained {
            @include justify-content(flex-end);
            @include align-items(flex-start);
            .bannerCopy{@include align-self(flex-start);text-align: right;}          

            text-align: right;
        }
        hr {
            margin-right: 0;
            margin-left: auto;
        }
    }
    .sliderMain-slide--leftCenter, .banner--leftCenter {
        .sliderMainContent-inner, .banner-contained {
            @include align-items(center);
            .bannerCopy{@include align-self(center);text-align: left;margin-right:auto;}          

            text-align: left;
        }
        hr {
            margin-right: auto;
            margin-left: 0;
        }
    }
    .sliderMain-slide--centerCenter, .banner--centerCenter {
        .sliderMainContent-inner, .banner-contained {
            @include justify-content(center);
            @include align-items(center);
            .bannerCopy{@include align-self(center);}            
        }
    }
    .sliderMain-slide--rightCenter, .banner--rightCenter {
        .sliderMainContent-inner, .banner-contained {
            @include justify-content(flex-end);
            @include align-items(center);
            .bannerCopy{@include align-self(center);text-align:left;}   
            text-align:right;   
        }
        hr {
            margin-right: 0;
            margin-left: auto;
        }
    }
    .sliderMain-slide--leftBottom, .banner--leftBottom {
        .sliderMainContent-inner, .banner-contained {
            @include justify-content(flex-start);
            @include align-items(flex-end);
            .bannerCopy{@include align-self(flex-end);text-align: left;}      

            text-align: left;
        }
        hr {
            margin-right: auto;
            margin-left: 0;
        }
    }
    .sliderMain-slide--centerBottom, .banner--centerBottom {
        .sliderMainContent-inner, .banner-contained {
            @include justify-content(center);
            @include align-items(flex-end);
            .bannerCopy{@include align-self(flex-end);}                    
        }
    }
    .sliderMain-slide--rightBottom, .banner--rightBottom {
        .sliderMainContent-inner, .banner-contained {
            @include justify-content(flex-end);
            @include align-items(flex-end);
           .bannerCopy{@include align-self(flex-end);text-align: left;}           


            text-align: right;

        }
        hr {
            margin-right: 0;
            margin-left: auto;
        }
    }
    .sliderMain-slide--textLight {
        a, .sliderMainContent-heading, .sliderMainContent-copy, .sliderMainContent-copy p {
            color: $colorWhite;
        }
    }
    .sliderMain-slide--textBackgroundLight {
        .sliderMainContent-copy {
            background: rgba($colorWhite, .95);
            padding:$spacingBase*1.25;
        }
    }
    .sliderMain-slide--textBackgroundDark {
        .sliderMainContent-copy {
            background: rgba($colorBrandPrimary, .95);
            padding:$spacingBase*1.25;   
            color:$colorWhite;        
        }

        a, .sliderMainContent-heading {
            color: $colorWhite;
        }
    }

}