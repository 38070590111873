/* TAG
========================================================================== */

/*
 * Create tags like user selections for search results, blog posts, etc.
 *
   <p> <span class="tag">music</span> <span class="tag">korean hip-hop</span> </p>
 *
 */
.tag {
    @include font-size(14px);
    display: inline-block;
    text-decoration:none;
    /*
     * These numbers set in ems mean that, at its narrowest, a label will be
     * the same width as the `line-height` set on the `html` element.
     * This allows us to use the `.label` in almost any `font-size` we wish.
     */

    min-width: ($lineHeightRatio * .666667) * 1em;
    padding-right: ($lineHeightRatio * .166667) * 1em;
    padding-left: ($lineHeightRatio * .166667) * 1em;

    text-align: center;

    background-color: $colorGreyLightest;
    + .tag {
        margin-left: .2em;
    }
    /*
    * Self closing tags
    *
        <span class="tag is-closeable">...</span>
    *
    * Script Library/front-end/isCloseable.js
    * On click, the alert gets wiped out through javascript
    * .net devs would need to be advised about making the closing persistent
    */
    &.is-closeable {
        cursor: pointer;
        &:after {
            display: inline-block;

            margin: 0 0 0 .4em;
            padding-left: .4em;

            content: 'X';

            border-left: 1px solid $colorWhite;
        }
        &:hover {
            color: $colorWhite;
            background: $colorDanger;
            &:after {
                color: $colorWhite;
            }
        }
    }
}
.h-tagStyle {
    @extend .tag;
    font-size:inherit;
    line-height:1.2;
    vertical-align:middle;
}
