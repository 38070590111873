.cookie-banner {

    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    background-color: $colorBrandPrimary;
    border-top: 6px solid $colorBrandSecondary;

    .dismiss-cookie {

        position: absolute;
        right: 10px;
        color: $colorWhite;
        font-size: 24px;
        cursor: pointer;

    }

    .cookie-wrapper {

        max-width: 960px;
        margin: 0 auto;
        padding: 32px 0;

        @include mq(xsm-only) {

            padding: 16px 0 10px;

        }

        .cookie-inner {

            margin-bottom: 16px;
            color: $colorWhite;
            padding: 0 20px;

            @include mq(lg) {

                padding: 0;

            }

            .cookie-header {

                margin-bottom: 8px;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;

                @include mq(xsm-only) {

                    display: none;


                }

                @include mq(sm-only) {

                    display: none;

                }

            }
              
            .cookie-description {

                font-weight: normal;
                font-size: 16px;
                line-height: 24px;

                @include mq(xsm-only) {

                    font-size: 12px;
                    line-height: 16px;

                }

                @include mq(sm-only) {

                    font-size: 12px;
                    line-height: 16px;

                }

            }

        }

        .cookie-actions {

            text-align: center;
            .btn.cookie-consent {
                background-color: $colorWhite;
                &:hover {
                    background-color: $colorBrandSecondary;
                }
            }
        }

    }

}