/* MAIN HEADER
========================================================================== */

.searchToggle {
    @extend %flexbox;

    @include align-items(stretch);
    // @include justify-content(center);

    position: relative;
    padding: $spacingSm;
    cursor: pointer;

    @include mq(lg) {
        //for IE can't use flex short hand: @include flex(0);
        @include flex(0 0 auto);
        border-width: 0;

        &:before {
            @include font-size(18px);
            width: auto;  
            color:$colorWhite;      
        }
        &:hover {
            background:$colorBrandPrimaryDarker;
        }        
    }

    &:before {
        @extend %iconStyle;
        @include font-size(20px);
        margin: 0;
    }
}
.searchToggle {
    @extend .icon-search;
}