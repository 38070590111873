/* <ASIDE>
========================================================================== */

@include mq(med) {
    aside {
        &.aside--right {
            float: right;

            max-width: 35%;
            margin: .35em 0 $spacingBase $spacingBase;
        }
        &.aside--left {
            float: left;

            max-width: 35%;
            margin: .35em $spacingBase $spacingBase 0;
        }
    }
}
