/* LOCATION FINDER
========================================================================== */

.locationFinder {
    @extend %flexbox;
    @include flex-direction(column);
    margin:0;
    // padding:0;
    //Below enacts same spacing as Spotlights
    padding:$spacingBase 0;
    @include mq(lg){
        padding:$spacingLg*.75 0;
    }
}
.locationFinder-content {
    @extend %noPhatBottom;
    @extend %flexbox;

    @include flex-direction(column);
    @include justify-content(center);

    min-width: 0;
    padding-top: $spacingBase;
    padding-right: $spacingBase;
    padding-bottom: $spacingBase;
    padding-left: $spacingBase;

    text-align: center;
    //stupid IE11 hack
    * {
        max-width: 100%;
    }
    #btnSearch {
        line-height:2;
    }
}
.locationFinder-heading {
    @include font-size(24px);
    font-family:$fontDefault;    
    margin:0 0 $spacingSm 0;
}
.locationFinder-heading, .locationFinder-content p {
    max-width:600px;
    margin-left:auto;
    margin-right:auto;
}
.locationFinder-proximity {
    margin-bottom: $spacingSm;
    // this is the label, which needs colors for ADA
    .h-visuallyHidden {
       background:$colorWhite;
       color:$colorText;
    }
}
input[type="text"].locationFinder-input {
    height:50px;
    max-width: 300px;
    text-align: center;
}
@include mq(sm){
    .locationFinder-proximity {
        display:flex;
        justify-content: center;
    }
    .locationFinder-input {
        margin:0 $spacingSm 0 0 !important;
    }
}

.locationFinder-map {
    display: none;
    @include mq(med) {
        @include flex(0 0 auto);
        display: block;

        iframe {
            width: 100%;
            min-height: 100%;
        }
    }
}

@include mq(med) {
    .locationFinder {
        @include flex-direction(row);
    }
    .locationFinder-content {
        //the 0% is another IE11 hack
        @include flex(1 1 50%);
        min-width: 0;
    }
    .locationFinder-map {
        @include flex(1 1 50%);
        min-width: 0;
    }
    .locationFinder-mapLeft {
        .locationFinder-map {
            order:1;
        }
        .locationFinder-content {
            order:2;
        }
    }
}
