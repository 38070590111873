/* FEATURE BLOCK
========================================================================== */

.spotlight {
}

.spotlight-inner {
	@extend %flexbox;
	@include flex-direction(column);
	//center and limit width so doesnt get crazy huge or get funky aspect ratio on large screens
	max-width:$startXXLg;
	margin-right:auto;
	margin-left:auto;
	position:relative;
	overflow:hidden;
	padding:$spacingBase 0;
	@include mq(lg){
		padding:$spacingLg*.75 0;
	}	
}

.spotlight-content {
	@extend %noPhatBottom;
	@extend %flexbox;
	@include align-items(flex-start);
	@include flex-direction(column);
	@include justify-content(center);
	min-width:0;
	padding-top:$spacingBase;
	padding-right:$spacingBase;
	padding-bottom:$spacingBase;
	padding-left:$spacingBase;
	text-align:left;
	z-index:1;
    &:before {
        content: " ";
        background: url('#{$pathTemplateImages}/ag-w.svg');
        background-size: contain;
        width: 70em;
        height: 70em;
        display: inline-block;
        font-family:$fontIcon;
        position:absolute;
        z-index:-1;
        left:-19em;
        top:10em;
    }	
	p + p {
		margin-bottom:$spacingSm;
	}
	//stupid IE11 hack
	* {
		max-width:100%;
	}
}
.spotlight-image {
	@include flex(0 0 auto);
	background-size:cover;
	background-position:center center;
	min-height: 250px;
}
.spotlight-heading {
	@include font-size($h4Size);
	line-height:1.2;
	margin-bottom:$spacingXSm;
	text-transform:initial;
	font-family:$fontHighlight;
	text-align:left;
}

@include mq(med) {
	.spotlight-inner {
		@include flex-direction(row);
        // height: 40vmin;
        min-height: 400px;
	}
	.spotlight-content {
		//the 0% is another IE11 hack
		@include flex(1 1 0%);
		min-width:0;
		margin-right:auto;
		max-width:610px;
		position:relative;
		z-index:1;
        &:before {
            left:-125px;
            top:auto;
            margin-top:50px;
        } 		
	}
	.spotlight-image {
		width:50%;
		min-height:400px;
	}
	.spotlight--imgRight {
		.spotlight-content {
			@include order(1);
			margin-left:auto;
			margin-right:0;
			&:before {
				left:auto;
				right:-125px;
			}
		}
		.spotlight-image {
			@include order(2);
		}
	}
}

@include mq(lg) {
	.spotlight-heading {
		@include font-size($h3Size);
		line-height:1.2;
	}	
	.spotlight-content {
		padding-right:$spacingLg;
		padding-left:$spacingLg;
	}	
}


.ag-spotlight-image.lazy, .agTitle.lazy {
    background-image: none !important;
}