/* The Modal (background) */
.loading-modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 15vh;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    text-align: center;


    /* Modal Content */
    .modal-content {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 0;
        width: 40vh;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;

    }

    /* Add Animation */
    @-webkit-keyframes animatetop {
        from {
            top: -10vh;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    @keyframes animatetop {
        from {
            top: -10vh;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }



    .modal-header {
        padding:$spacingSm;
        background-color: $colorBrandPrimary;
        color: white;

        h2 {
            margin-bottom: 0;
        }

    }

    .modal-body {
        margin: 5vh;

        .btn {
            display: none;
            margin: auto;
            margin-top:$spacingSm;
        }

        .completed {
            display: none;
        }

        .loader {
            display: block;
            margin-top: 16px;
        }
        .error-form{
            display:none;
        }
    }

    .modal-footer {
        padding: 2px 16px;
        background-color: $colorBrandPrimary;
        color: white;
    }

    &.success {

        .modal-header,
        .modal-footer {
            background-color: $colorSuccess;
        }

        .loading {
            display: none;
        }

        .btn {
            display: block;
            background-color: $colorSuccess;
        }

        .completed {
            display: block;
        }
    }
}