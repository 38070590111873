/* COLORS
========================================================================== */

/*
 * Declare brand colors here as they may be used in some of the variables below.
 *
 * The default secondary and accent colors are populated with SASS color functions to
 * generate a 3 color analogous palette (hue values 30 degrees to either side on the color wheel).
 * If the client has a full color palette those hex values should be entered instead

 * 
 Just in case you want to mess with different color rules:
    Monochromatic color harmony maintains a constant value in the H and varies the S and V values.

    Complementary color harmony uses H values that are about 200 degrees apart (with variations
    in S and V as desired).

    Triadic color harmony uses 3 H values roughly 130 degrees apart (vary S and V to taste).

    Analogous color harmony uses 3 or more H values in a range of about 30 degrees plus and
    minus from your key color.

 */

$colorBrandPrimary: #DA291C !default;
$colorBrandPrimaryLight: mix(white, $colorBrandPrimary, 5%) !default;
$colorBrandPrimaryLighter: mix(white, $colorBrandPrimary, 10%) !default;
$colorBrandPrimaryLightest: mix(white, $colorBrandPrimary, 15%) !default;
$colorBrandPrimaryDark: mix(black, $colorBrandPrimary, 10%) !default;
$colorBrandPrimaryDarker: mix(black, $colorBrandPrimary, 15%) !default;
$colorBrandPrimaryDarkest: mix(black, $colorBrandPrimary, 20%) !default;

$colorBrandSecondary: #75787B !default; // Brand Grey - same as grey below
$colorBrandSecondaryLight: mix(white, $colorBrandSecondary, 25%) !default;
$colorBrandSecondaryLighter: mix(white, $colorBrandSecondary, 50%) !default;
$colorBrandSecondaryLightest: mix(white, $colorBrandSecondary, 75%) !default;
$colorBrandSecondaryDark: mix(black, $colorBrandSecondary, 25%) !default;
$colorBrandSecondaryDarker: mix(black, $colorBrandSecondary, 50%) !default;
$colorBrandSecondaryDarkest: mix(black, $colorBrandSecondary, 75%) !default;

$colorAccent: #569197 !default; // Secondary Teal
$colorAccentLight: mix(white, $colorAccent, 25%) !default;
$colorAccentLighter: mix(white, $colorAccent, 50%) !default;
$colorAccentLightest: mix(white, $colorAccent, 75%) !default;
$colorAccentDark: mix(black, $colorAccent, 25%) !default;
$colorAccentDarker: mix(black, $colorAccent, 50%) !default;
$colorAccentDarkest: mix(black, $colorAccent, 75%) !default;

/*
 * Standard colors to convey messaging
*
* !!! These colors need to be dark enough to work as a background for white text
*/

$colorHighlight: #f68932 !default; //this is a high visibility color like orange.
$colorSuccess: #42be2a !default; //this is usually a green
$colorWarning: #f9b106 !default; //this is usually a yellow or orange
$colorDanger: #e31836 !default; //this is usually a red
$colorInfo: #5bc0de !default; //this is usually a red

$colorAGYellow:$colorWarning;
/*
 * Monochrome colors
 */

$colorWhite: #fff !default;
$colorBlack: #000 !default;
$colorGrey: #75787B !default;
$colorGreyLight: mix(white, $colorGrey, 60%) !default;
$colorGreyLighter: #f2f2f2 !default;
$colorGreyLightest: mix(white, $colorGrey, 95%) !default;
$colorGreyDark: mix(black, $colorGrey, 25%) !default;
$colorGreyDarker: mix(black, $colorGrey, 35%) !default;
$colorGreyDarkest: mix(black, $colorGrey, 55%) !default;

/*
 * Text elements
 */

$colorText: $colorBlack !default; //color overall body copy
$colorLinks: $colorBrandPrimary !default; //color basic links sitewide
$colorLinksHover: $colorBrandPrimaryDarkest !default; //color basic links hover color sitewide

/*
 * Generic border color for consistency
 */
 $colorBorder: darken($colorGreyLighter,5%) !default;

 $colorAGYellow:#f8aa1d;
 $colorAGTeal:#569197;
 $colorAGBlue:#406184;
 $colorAGBrick:#942522;
