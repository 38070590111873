/* RULES
========================================================================== */

/*
 * Dotted rules
*/
.rule--dotted {
    border-bottom-style: dotted;
}

/*
 * Dashed rules
 */
.rule--dashed {
    border-bottom-style: dashed;
}


/*
 * Ornamental rules. Places a § over the rule.
 */
.rule--ornament {
    position: relative;
    &:after {
        line-height: 0;

        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        content: '\00A7';
        text-align: center;
    }
    /*
    * Pass in an arbitrary ornament though a data attribute, e.g.:
    *
      <hr class="rule  rule--ornament" data-ornament="!">
    *
    */
    &[data-ornament]:after {
        content: attr(data-ornament);
    }
}
