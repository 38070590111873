/* CONTAINED
========================================================================== */

/*
 * Constrain elements
 * Extend the contained placeholder with Sass to avoid the `.contained` class appearing over
 * and over in your markup.
 * e.g.
 * main { @extend %contained; }
 */

 %contained {
    margin-left: auto;
    margin-right: auto;
    max-width: $maxWidth;
}

//mixin for use in media queries or anyplace else extending doesnt make sense
@mixin contained {
    @extend %contained;
}

//mixin for use in media queries or anyplace else extending doesnt make sense
.contained {
	@extend %contained;
}