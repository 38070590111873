/* MAIN HEADER
========================================================================== */

// Establish common styles for utility and user nav
.navUtil {
    display:none;
    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        height:100%;

        > li {
            position: relative;

            @include mq(lg) {
                &:hover {
                    > a {
                        background:$colorGreyLightest;
                    }
                    > ul {
                        -webkit-transform: scale(1,1);
                            -ms-transform: scale(1,1);
                                transform: scale(1,1);
                    }
                }
            }

            > a, > span {
                position: relative;
                overflow: hidden;
                padding: 3px $spacingSm;
            }
            &.btn, &.btn a {
                @include font-size(14px);
                line-height:2;                
                display:flex;
                align-self:stretch;
                background:$colorBrandPrimaryDarker;                
                border:none;
                padding:0;
                border-radius:5px;
                color:$colorWhite !important;
                &:hover {background:darken($colorBrandPrimaryDarker,10%);}
            }    
            &.btn a {padding: 0 $spacingSm*1.5}    
            &.btn {margin:$spacingXSm 0;}   
            > ul {
                transition:all .2s ease-in-out;
                @include flex-direction(column);

                position: absolute;
                z-index: 10;
                top: 100%;
                left: 0;

                min-width: 200px;
                margin: 0;
                padding: $spacingXSm;

                list-style: none;

                -webkit-transform: scale(1,0);
                    -ms-transform: scale(1,0);
                        transform: scale(1,0);
                -webkit-transform-origin: top;
                    -ms-transform-origin: top;
                        transform-origin: top;

                background: rgba($colorGreyLightest,.95);

                > li {
                    a, span {
                        transition:all .25s ease-in-out;
                        display: block;
                        padding: 0 $spacingXSm;
                        text-decoration: none;
                        &:hover {
                            text-decoration:underline;
                        }
                    }
                }
            }
        }
    }
}

// Specific styling for headerMainNavUtil
.navUtil {

    @include mq(lg) {
        display: block;

        margin-right: auto;

        > ul {
            @include flexbox;
            @include align-items(center);

            //add arrows after links with babykids
            > li {
                > a {
                    display: block;
                }
                &.hasChildren {
                    > a {
                        &:after {
                          display: inline-block;

                          width: 0;
                          height: 0;
                          margin-left: $spacingXSm;

                          content: '';
                          vertical-align: middle;

                          border-top: 4px solid;
                          border-right: 4px solid transparent;
                          border-left: 4px solid transparent;
                        }
                    }
                }
            }
        }
    }
}
