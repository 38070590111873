/* MAIN HEADER
========================================================================== */

.locSearch {
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(center);
    padding-bottom: $spacingXSm;
    width:100%;
    transition:all 10s ease-in-out;
    position:relative;

    @include mq(med) {
        padding-top: $spacingXSm;
    }
    @include mq(lg) {
        padding-top: 0;
        padding-bottom: 0;
        margin-right:$spacingBase;
    }
}
a.locSearch-current {
    @include font-size(14px);
    padding-right:$spacingXSm*.5;
    color:$colorText;
}
.locSearch-label {
    font-size:0;
    margin: 0;
    padding: 0;
    background:$colorWhite;
    color:$colorText;
}
.locSearch-inputWrap {
    @extend %flexbox;
    @extend .icon-location;
    @include align-items(center);
    position:relative;
    padding: 2px;
    &:before {
        @extend %iconStyle;
        margin-right: $spacingSm;
        color: $colorGrey;
    }
}
input[type='text'].locSearch-input {
    @include font-size(15px);
    font-weight:normal;
    width: auto;
    min-width:200px;
    height: 30px;
    margin: 0;
    margin-right: $spacingXSm;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 0;
    &:focus {
        background:$colorWhite;
        border-bottom:2px solid $colorBorder;
    }
    @include mq(lg) {
        min-width:250px;
    }
}
.locSearch-submit {
    @include font-size(14px);
    line-height:30px;
    padding: 0 $spacingXSm;
    color:$colorWhite;
    background: $colorBrandSecondaryDark;
    position:absolute;
    border-radius:0 4px 4px 0;
    right:0;
    &:hover {
        color:$colorWhite;
        background:$colorBrandSecondaryDarker;
    }
}
.locSearch.locSearch-mobile {
    background: $colorBrandPrimary;
    padding:$spacingSm 0;
    .locSearch-current, .locSearch-inputWrap:before {color:$colorWhite;}

}
