/* SPACING UNITS
========================================================================== */

/*
* Try and use only these values to set typography spacing (margins) across site for consistency.
*
* $spacingBase is the same as default line height above. This value is then increased/decreased by halves.
*
* These values can be used in classes for tweaking spacing and are used in _base/helper-classes.scss
* styles for one-off use cases where an entire new class is not necessary
*/

$spacingBase: $lineHeight !default;
$spacingSm: $spacingBase/2 !default;
$spacingXSm: $spacingSm/2 !default;
$spacingLg: $spacingBase*2 !default;
$spacingXLg: $spacingLg*2 !default;



