/* RULES
========================================================================== */

hr {
    clear: both;

    margin-bottom: $spacingBase - 1px;
    margin-bottom: (($spacingBase - 1px) / $fontSizeBase)*1rem;

    color: $colorBorder;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
