/* <IMG>
========================================================================== */ 

/*
 * Rounded images.
 */
.img--round { 
    border-radius:50%;
}

/*
 * Image placement variations.
 */
.img--right{
    float:right;
    margin-bottom:$spacingBase;
    margin-left:$spacingBase;
}
.img--left{
    float:left;
    margin-right:$spacingBase;
    margin-bottom:$spacingBase;
}
.img--center{
    display:block;
    margin-right:auto;
    margin-bottom:$spacingBase;
    margin-left:auto;
}
