/* Service Offerings Listing
========================================================================== */


.serviceOfferingListing {
    padding:$spacingLg $gutterBase;
    .serviceOfferingListing-heading {
        @extend .featureBlock-heading;
        margin-bottom:$spacingLg;
    }
    .serviceOffering-heading {
        @include font-size($h5Size*1.1);
        margin:$spacingSm 0 $spacingXSm 0;
        font-weight:bold;
        font-family:$fontDefault;
    }
    .serviceOffering {
        figure {
            float:right;
            img {max-height:150px;}
        }
        margin:0;
        padding:0;
        @include mq(lg) {
            p {margin-bottom:0;}
        }
    }
    h3.toggleLink {
        @include font-size(16px);
        font-family:$fontDefault;
        padding-top:0;
        margin:0 0 $spacingSm 0;
        font-weight:normal;
        a { color:$colorBlack }
    }
    ul.toggleLinkTarget {
        @extend .list--bare;
        @include font-size(15px);
        line-height:1.2;
        li {padding:0 0 $spacingXSm 0;}
        a {
            text-decoration:none;
            &:hover {
                text-decoration:underline;
            }
        }
    }
    .serviceOffering-list {
        border-bottom:1px dotted $colorGrey;
        margin-bottom:$spacingBase;
    }
    .serviceOffferings-heading {}
    @include mq(xlg) {
        padding:$spacingLg 0;
    }
}

// Listing Styles are based off List Images
.serviceLandingListing {
    padding:$spacingLg $spacingBase;
    .listImages-figure, .tabs > li > a + div .listImages-figure:last-child {
        margin-bottom:$spacingBase*1.5
    }
    .listImages-figcaption {
        @include font-size(15px);
    }
    .listImages-subHeading {
        @include font-size(19px);
    }
    @include mq(med) {
        .tabs.tabs--accordion {
            margin:0;
            > li {
                & > a {
                    font-weight:normal;
                    color:$colorBlack;
                    margin:0 $spacingXSm 0 0;
                    padding:$spacingSm $spacingXSm;
                    &.is-active {
                        background:transparent;
                    }
                }          
            }
        }
    }
}