/* HEADINGS
========================================================================== */

/** Put all styles to indiviual elements 
Helps for compiling style dropdown **/

h1, h2, h3, h4, h5, h6 {
    font-family: $fontDefault;	
    a {text-decoration:none;}
    i{
        display:block;
        font-weight:300;
        font-size:90%;
    }
    // span {
    //     &[class*='icon'] {
    //         display:inline-block;
    //         font-size:100%;
    //     }     
    // }
}
h1 {
    font-family: $fontHighlight;        
    @include font-size($h1Size);
    // color:$colorBrandPrimary;
    color:$colorBlack;
    font-weight:bold;
    line-height:1.4;    
}
h2 {
    @include font-size($h2Size);
    font-family: $fontHighlight;    
    line-height:1.3;            
}
h3 {
    @include font-size($h3Size);
    line-height:1.3;    
}
h4 {
    @include font-size($h4Size);
    margin-bottom:$spacingSm;
    text-transform:uppercase;
    line-height:1.3;    
}
h5 {
    @include font-size($h5Size);
    margin-bottom:$spacingSm; 
    line-height:1.5;
}
h6 {
    @include font-size($h6Size);
    text-transform:uppercase;
    margin-bottom:$spacingSm; 
    line-height:1.5;      
}

