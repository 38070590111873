/* ALERTS
========================================================================== */

/*
* Style the default form error messaging for iAPPS
*/

.form-error,
.validator {
  @include font-size(15px);
  background: $colorDanger;
  color: $colorWhite;
  display: block;
  padding: 2px $spacingXSm;
  margin: (-$spacingSm) 0 $spacingSm 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: $spacingXSm;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $colorDanger;
  }
  &[style*="inline"] {
    display: block !important; 
  }
}

