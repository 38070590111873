/*  FLOATS
========================================================================== */

/*
* Add/remove floats
*/

.h-floatRight {
    float: right!important;
}
.h-floatLeft {
    float: left !important;
}
.h-floatNone {
    float: none !important;
}
