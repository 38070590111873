/* HORIZONTAL NAV
========================================================================== */ 

/*
* !!! we are using the class of .lastSelected instead of .selected to
* target the current page link because iAPPS nav control adds .selected
* to both the the direct parent LI of the current page link and also IT's parent
* if it is a nested link
 <nav class="navHorizontal">
		<ul>
		<li class="selected">
			<a href="">We aint here</a>
			<ul>
				<li class="selected">
					<a href="">We're actually here. Screwy huh?</a>
				</li>
			</ul>
		</li>
 </nav>
 *
 * There's a lil snippet in frontEnd.js that adds the class .lastSelected to the last
 * instance of .selected to tell us where we really are.
 */

 .navHorizontal ul {
	list-style:none;
	padding:0;
	display:table;
	width:100%;
	border-bottom:1px solid $colorBorder;
	li {
		display:none;
		a {
			display:block;
			padding:$spacingSm 0;
			position:relative;
			color:inherit;
		}
		&.selected {
			display:table-header-group !important;
			a {
				border-top: 1px solid $colorBorder;
				@extend .icon-caret-down;
				&:before {
					@extend %iconStyle;
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -11px;
				}
			}
		}
	}
	&.is-open {
		li {
			&:not(.active) {
				display:table-row;
			}
			&.selected a {
				@extend .icon-close;
			}
		}
	}

}

@include mq(med) {

	.navHorizontal ul {
		display:block;
		border-top-width:0;
		border-bottom:1px solid $colorBorder;
		li {
			display: inline-block !important;
			margin: 0;
			a {
				@include font-size(14px);
				padding: $spacingSm;
				cursor: pointer;
				display: inline-block;
				clear: both;
				margin-bottom:-1px;
				border-bottom: 1px solid transparent;
				&:hover {
					text-decoration: none;
					color: inherit;
					border-color: $colorLinksHover;
					color:$colorLinksHover;
				}
				.bug {
					@include font-size(11px);
					color: $colorWhite;
					border-radius: 20px;
					width: $spacingBase*1.75;
					height: $spacingBase*1.75;
					display: inline-block;
					line-height: 1.75;
					text-align: center;
					-webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
					-moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
					box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
					position: absolute;
					top: 80%;
					left: 50%;
					margin-left: -$spacingBase*.875;
					&:after {
						content: '';
						position: absolute;
						top: -5px;
						left: 50%;
						margin-left: -6px;
						width: 0;
						height: 0;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-bottom: 7px solid $colorLinksHover;
						z-index: -1;
					}
				}
			}
			&.selected {
				display: inline-block !important;
				font-weight:500;
				a {
					border-top-width:0;
					color: inherit;
					border-bottom: 2px solid $colorLinksHover;
					color:$colorLinksHover;
					&:hover {
						cursor: default;
					}
					&:before {
						display:none;
					}
				}
			}
		}
	}
	.navHorizontal--hiVis {
		margin-bottom:$spacingLg;
		ul li a {
			@include font-size(16px);
			text-transform:uppercase;
			font-weight:bold;
		}
	}	

}

.navHorizontal.navSection {
	background:$colorAGBrick;
    .toggleLink {
        font-weight: bold;
    }
    nav {
    	> ul {
    		@extend .contained;
    		margin-bottom:0;
    		background:transparent;
    		border-bottom:none;
    		// padding:$spacingXSm 0;
    		> li {
    			display: block !important;
                position: relative;
                flex: 1;
                @include mq(med) {
                    text-align: center;
                }
                &:hover {
                    background:$colorBrandPrimary;
                }
                > a {
                    height: 50px;
    				padding: $spacingSm;
    				margin:0;
    				color:$colorWhite;	
    				border-bottom:0px;
                    text-decoration:none;
                    font-weight: bold;
                    // font-size: 24px;
                    // line-height: 24px;
                    line-height: 1em;
                    font-size: 110%;
                    font-family: $fontHighlight;
    			}
    			&.selected > a, &.default > a {
    				&:hover {
    					background:$colorBrandPrimary;
    				}
    			}
                &.hasChildren {
                    .navHorizontal-mobile-subToggle {
                        @extend .icon-plus;
                        position: relative;
                        z-index: 2;
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        padding: $spacingSm;
                        color: $colorWhite;
                        &:before {
                            @extend %iconStyle;
                            margin: 0;
                        }
                        @include mq(med) {
                            display: none;
                        }
                    }
                    > ul {
                        display: none;
                        @include font-size(15px);
                        line-height:1.2;
                        transition:all .2s ease-in-out;
                        @include flex-direction(column);
                        @include mq(med) {
                            position: absolute;
                            top: 100%;
                            right: 0;
                            transform: scale(1,0);
                            transform-origin: top;
                        }
                        z-index: 10;
                        background: #fff;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        border-radius: 4px;
                        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                        > li {
                            display: list-item;
                            text-align: left;
                            > a {
                                text-decoration: none;
                                padding: 6.5px 13px;
                                &:hover {
                                    border: none;
                                }
                            }
                        }
                    }
                    &.is-active {
                        .navHorizontal-mobile-subToggle {
                            @extend .icon-minus;
                            @include mq(med) {
                                display: none;
                            }
                        }
                        > ul {
                            display: table;
                            -ms-transform: scale(1,1);
                            -webkit-transform: scale(1,1);
                            transform: scale(1,1);
                        }
                    }
                }
                @include mq(med) {
                    &:hover {
                        > ul {
                            display: flex;
                            -ms-transform: scale(1,1);
                            -webkit-transform: scale(1,1);
                            transform: scale(1,1);
                        }
                    }
                }
    		}
    	}
    }

	&.is-sticky {
		left:0;
		right:0;
		z-index:3;
		// margin-top:-32px;
	}
	.toggleLink {
		color:$colorWhite;
		position:relative;
		@include font-size(18px);
		font-weight:bold;
		padding:$spacingSm;
		@extend .icon-caret-down;
		margin:0;
		&:before {
			@extend %iconStyle;
			position: absolute;
			right: 5px;
			top: 15px;
			margin-top: 0;
		}
		&.is-active {
			@extend .icon-caret-up;
			border-bottom:1px solid $colorBrandPrimaryDarker;		
		}
		
	}
	@include mq(med) {
		.toggleLink {
			display:none;
		}
		ul, ul.toggleLinkTarget.is-hidden {
			display:flex;
			justify-content:space-between;
    -webkit-transform: scale(1,1); /* Chrome, Safari, Opera */
        -ms-transform: scale(1,1); /* IE 9 */
            transform: scale(1,1); /* Standard syntax */
    -webkit-transform-origin: top; /* Chrome, Safari, Opera */
        -ms-transform-origin: top; /* IE 9 */
            transform-origin: top;
            position:relative;			
		}
		&.is-sticky {
			margin-top:-1px;
		}
	}
}