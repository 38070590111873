/* MAIN HEADER
========================================================================== */

.locBlurb {
    @include font-size(15px);
    @extend .icon-location;

    margin: 0;
    list-style: none;
    color: $colorText;
    font-weight:normal;
    li {
        display: block;
        position:relative;
        line-height:2;
    }
    > li:first-child {
        @include font-size(19px);
        font-weight:bold;
    }
    .icon-toggle {position:absolute;margin:0 $spacingSm;}
    // Location Icon
    &:before {
        @extend %iconStyle;
        color:$colorWhite;
        position:absolute;
        margin: 4px 0 0 (-$spacingBase*.9);
    }
}
a.locBlurb-edit {
    color:$colorText;
    text-decoration:underline;
    margin:0 0 0 $spacingSm;
    &:hover {
        text-decoration:underline;
        color:$colorBrandPrimary;
    }
}
.locBlurb-mobile {
    background:$colorBrandPrimary;
    color:$colorWhite;
    padding-top:$spacingSm;
    padding-bottom:$spacingSm;
    a.locBlurb-edit {color:$colorWhite}
}
@include mq(lg) {
    .locBlurb {
        &:before {color:$colorGrey}
        a.locBlurb-edit {
            @include font-size(12px);
        }
        > li:first-child {line-height:1.2;color:$colorGreyDark;} 
        .icon-toggle {display:none}
        .toggleLinkTarget.is-hidden, .toggleLinkTarget {
            transform: scale(1,1) !important; /* Standard syntax */
            position:relative;
        }               
    }
}

@include mq(xlg) {
    .locBlurb .toggleLinkTarget {
        position:relative;
    }
}