/*  FONT SIZES
========================================================================== */

/*
* Font sizes
* 
* Emulate look of all header styles for SEO purposes, manage by extension
*/

.h-h1 {
    @extend h1;
}
.h-h2 {
     @extend h2;
}
.h-h3 {
     @extend h3;
}
.h-h4 {
     @extend h4;
}
.h-h5 {
     @extend h5;
}
.h-h6 {
     @extend h6;
}
.h-textXSm {
    @include font-size($fontSizeXSm);
}
.h-textSm {
    @include font-size($fontSizeSm);
}
.h-textBase {
    @include font-size(17px);
    line-height:1.2;    
}
.h-textLg {
    @include font-size($fontSizeLg);
    line-height:1.2;
}
.h-textXLg {
    @include font-size($fontSizeXLg);
    line-height:1.2;    
}
