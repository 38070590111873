.agEnterprise {
    h1 {
        font-size: 24pt;
    }
    h2, h3 {
        font-size: 16pt;
    }
    p, li {
        font-size: 12pt;
    }
    .spotlight-inner {
        padding-block: 0;
    }
    .spotlight-content {
        &:before {
            background: none;
        }
    }
    .no-list-style {
        list-style: none;
    }
    .list-spacing {
        > li {
            &:not(:last-of-type) {
                margin-bottom: $spacingBase;
            }
        }
    }
    .pageTitle {
        @include mq(lg) {
            min-height: 500px;
        }
    }
    .align-center {
        align-items: center;
    }
    .marketingDownload-inner {
        .marketingDownload-body {
            .form-body {
                padding-top: 0;
            }
        }
    }
}