/*BLOG ITEM
=======================================================================================*/

// .blogList {	}
.blogItem {
	@extend %noPhatBottom;
	@extend %flexbox;
	@include flex-direction(column);
	justify-content:flex-start;
	border-bottom:1px solid $colorBorder;
	padding:$spacingBase 0;
	position:relative;
	a{
		color:$colorText;
		text-decoration:none;
		&:hover {
			color:$colorLinks;
			text-decoration:underline;			
		}
		img{
			max-height: 168px;
		}
	}	
}
.blogItem-body {
	.blogItem-infoList {
		margin:0 0 $spacingSm 0;
		@include mq(lg) {
			margin:0 0 $spacingXSm 0;
		}
	}
}
.blogItem-heading {
	@include font-size($h3Size);
	line-height:1.2;
	margin-bottom:$spacingXSm;
}
.blogItem-figure {
	text-align:left;
	margin-bottom:$spacingSm;
}
 @include mq(lg) {
 	.blogItem {
		@include flex-direction(row);
 	}
 	.blogItem-body {
 		order:1;
 	}
 	.blogItem-figure {
 		margin:0 0 0 $spacingBase;
 		flex-shrink:0;
 		order:2;
 	}
	.blogItem-blurb {
		display:block;
		position:relative;
		padding-left:$spacingBase;
		&:before {
			content:'';
			height:94%;
			border-left:5px solid $colorBrandPrimary;
			position:absolute;
			left:0;
			margin-top:$spacingXSm;
		}
				
	}
 }