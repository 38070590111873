/* VISUALLY HIDDEN
========================================================================== */
/*
 * Hide content off-screen without resorting to `display:none;`
 */
%visuallyHidden{
    border:0!important;
    clip:rect(0 0 0 0)!important;
    height:1px!important;
    margin:-1px!important;
    overflow:hidden!important;
    padding:0!important;
    position: absolute!important;
    width:1px!important;
}
//mixin for use in media queries or anyplace else extending doesnt make sense
@mixin visuallyHidden {
    @extend %visuallyHidden;
}
