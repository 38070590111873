/* TABS
========================================================================== */

.tabs {
    @extend %clearfix;

    padding: 0;
    & > li {
        display: inline;

        list-style: none;
        & > a {
            position: relative;
            display: inline-block;
            clear: both;
            padding:$spacingSm $spacingBase;
            cursor: pointer;
            text-decoration: none;
            color: inherit;
            border-bottom: 3px solid transparent;
            .no-touch &:hover {
                color: $colorBrandPrimary;
                border-color: $colorBrandPrimary;
            }
            & + div {
                display: none;
                float: left;

                width: 100%;
                max-width: 100%;
                margin-top: -1px;
                padding: $spacingBase 0;

                border-top: 1px solid $colorBorder;
                *:last-child {
                    margin-bottom: 0;
                }
            }
            &.is-active {
                color: $colorBrandPrimary;
                border-bottom: 3px solid $colorBrandPrimary;
                .no-touch &:hover {
                    cursor: default;
                }
                & + div {
                    display: block;
                    background:$colorGreyLightest;
                }
            }
        }
    }
    /*
	* For tabs that turn into an accordion pattern on small screens
	*/
    &.tabs--accordion {
        border-bottom-width: 0;
        > li {
            display: block;
            & > a {
                display: block;
                padding: $spacingSm 0;
                @include font-size(16px);
                font-weight:bold;
                color:$colorGreyDark;
                border-bottom-color: $colorBorder;
                &:after {
                    @extend %iconStyle;

                    position: absolute;
                    top: 50%;
                    right: .4em;

                    margin: 0;
                    margin-top: -.5em;
                    padding: .1em;
                    content: '\e988';
                    color:$colorGreyDark;
                }
                &.is-active:after {
                    content: '\e987';
                }
                & + div {
                    margin-bottom: 0;
                    padding: $spacingSm;

                    border-top-width: 0;
                }
            }
        }
    }
}
@include mq(med) {
    /*
	* Once we get to larger screens, set the accordion back to a tab pattern
	*/
    .tabs {
        &.tabs--accordion {
            border-bottom-width: 1px;
            > li {
                display: inline;
                & > a {
                    display: inline-block;
                    margin:0;
                    padding: $spacingSm $spacingSm*1.5;
                    border-bottom-color: transparent;
                    &.is-active {
                        background:$colorGreyLightest;
                        border-bottom-color: $colorBrandPrimary;
                    }
                    &:after {
                        display: none;
                    }
                    & + div {
                        padding: $spacingBase 0;
                        border-top-width: 1px;
                        background:$colorWhite;
                    }
                }
            }
        }
    }
}
