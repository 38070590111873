/* STYLEGUIDE HEADER
========================================================================== */

.headerStyleguide {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    display: block;
    overflow: hidden;

    width: 100%;

    background-color: $colorBrandPrimary;
}

.headerStyleguide-container {
    @include flexbox;
    @include flex-direction(row);

    position: relative;

    width: 100%;
    height: 60px;
    margin: 0 auto;
}

.headerStyleguide .headerStyleguide-title {
    @include flex(0 1 auto);

    display: initial;
    float: none;

    max-width: none;
    margin: 0 16px;
    padding: 0;

    font-size: 28px;
    font-weight: normal;
    line-height: 60px;

    color: $colorWhite;
}
.headerStyleguide-title .headerStyleguide-client {
    display: none;
}
.headerStyleguide .headerStyleguide-title a {
    text-decoration: none;
    font-weight: normal;

    color: inherit;
}

.headerStyleguide-actions {
    @include flexbox;
    @include flex-direction(row);

    z-index: 2;
}

.headerStyleguide button {
    display: inline-block;

    width: 60px;
    height: 60px;
    padding: 0;

    transition: background-color .2s ease-out;

    border: none;
    background-color: transparent;
    background-image: none;

    -webkit-tap-highlight-color: transparent;

    &.menu {
        @extend .icon-menu;

        color: $colorWhite;

        &:before {
            @extend %iconStyle;

            @include font-size($h2Size);

            margin: 0;
        }
    }
}

.headerStyleguide button img {
    width: 24px;
    height: 24px;
}

.headerStyleguide button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.headerStyleguide button:focus {
    outline: 0;
    background-color: rgba(255, 255, 255, .2);
}

.headerStyleguide button:active {
    background-color: rgba(255, 255, 255, .4);
}

.navStyleguide {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;

    overflow-y: auto;

    width: 250px;
    height: 100%;

    -webkit-transform: translate(-250px, 0);
        -ms-transform: translate(-250px, 0);
            transform: translate(-250px, 0);

    color: $colorWhite;
    background-color: $colorBrandPrimary;
}

.styleGuidePageMask {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;

    visibility: hidden;

    width: 100%;
    height: 100%;

    -webkit-transform: translateZ(0px);
        -ms-transform: translateZ(0px);
            transform: translateZ(0px);

    opacity: .1;
    background-color: rgba(0, 0, 0, .5);
}
body.is-navActive {
    overflow: hidden;

    .navStyleguide {
        -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    .headerStyleguide {
        -webkit-transform: translate(250px, 0);
            -ms-transform: translate(250px, 0);
                transform: translate(250px, 0);
    }
    .styleGuidePageMask {
        visibility: visible;

        opacity: 1;
    }
}

.headerStyleguide,
.navStyleguide {
    transition:transform .3s ease-out;
}

.navStyleguide h4,
.navStyleguide .navStyleguide-header,
.navStyleguide ul li a {
    display: block;

    margin: 0;
    padding: $spacingSm;

    line-height: 1.4;
}

.navStyleguide h4 {
    height: 60px;

    color: #3367d6;
    background-color: white;
}

.navStyleguide ul {
    margin: 0;
    padding: 0;

    list-style-type: none;
}

.navStyleguide ul li a {
    display: block;
    transition: background-color .2s ease-out;
    white-space: nowrap;
    text-decoration: none;

    color: white;
}

.navStyleguide ul li {
    padding: 0;

    border-width: 1px;
    border-color: white;
    border-bottom-style: solid;
}

.navStyleguide ul li::before {
    content: none;
}

.navStyleguide ul li a,
.navStyleguide .navStyleguide-header, {
    &:hover,
    &:focus,
    &:active,
    &.showSub {
        cursor: pointer;

        outline: 0;
        background-color: rgba(255, 255, 255, .2);
    }
}

/** sub menus */
.navStyleguide ul li ul {
    transition:all .2s ease-in-out;

    overflow: hidden;

    max-height: 0;
}

.navStyleguide .showSub + ul {
    max-height: 10000px;

    li {
        border-bottom-width: 0;

        a {
            padding: $spacingXSm $spacingBase;
        }
    }
}

@include mq(med) {
    .headerStyleguide-title .headerStyleguide-client {
        @include font-size(18px);

        display: inline-block;
    }
}
