/* FEATURE BLOCK
========================================================================== */
// Top Level Container
.detailBlockListing {
    padding:$spacingLg 0 0;
}
// Holds all Detail Blocks
.detailBlockListing--inner {
    @extend .row;
    margin:0;
    justify-content:center;
    @include mq(xlg){
        margin-left:-$gutterBase;
        margin-right:-$gutterBase;
    }
}
// Invidual Detail Block
.detailBlock {
    @extend .column;
    margin-bottom:$spacingLg*1.5;
    figure {
        margin:0 0 $spacingSm 0;
    }
    .detailBlock-inner {
        height:100%;
        position:relative;
    }
    &.detailBlock--textLeft {
        text-align:left;
        .detailBlock-CTA {text-align:left !important;}     
        .detailBlock-heading {justify-content:flex-start !important;}           
    }
    &.detailBlock--textCenter {
        text-align:center;
        .detailBlock-content, .detailBlock-CTA {text-align:center;}
    }
    @include mq(med) {
        &.detailBlock--imgRight {
            figure {
                max-width:37%;
                float:right;
                margin:0 0 $spacingSm $spacingBase;
            }
        }
        &.detailBlock--imgLeft {
            figure {
                max-width:37%;
                float:left;
                margin:0 $spacingBase $spacingSm 0;
            }
        }        
    }

}
// Inside the Detail Block
.detailBlock-heading {
    @extend .featureBlock-subHeading;
    @include font-size(20px);
    padding:$spacingSm*1.5 0 $spacingSm;
    font-weight:bold;
    text-align:center;
    font-family:$fontDefault;
    @include mq(lg) {
        height:60px;
        line-height:1.2;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:$spacingSm*1.25 0;
        margin:0;
    }
}
.detailBlock-content {
    overflow:hidden;
    figure:first-child img {padding:0 0 $spacingSm 0;}
    p, img, {
        padding:$spacingSm 0;
        margin:0;
        line-height:1.5;
        &.lead {
            padding:0 $spacingSm 0 $spacingBase;
        }
    }
    h1,h2,h3,h4,h5,h6 {
        // margin:0;
        // padding:$spacingSm 0 0 0;
        text-transform:initial;
        a:hover {text-decoration:underline !important;}

    }
    p:last-of-type {margin-bottom:$spacingSm}
    img {margin:0 auto;}    
    ul, ul.list--columns {
        text-align:left;
        margin-left:$spacingBase*.75;
        &.list--block {
            margin-left:0;
            &:last-child {margin-bottom:0;}
        }
    }
    .btn--full {
        left:0;
        right:0;
    }
    ~ .detailBlock-CTA {
        // padding-top:$spacingSm;
        a.btn {
            border-radius:0;
            left:0;
            right:0;
            width:100%;
            &:hover {
                background-color:$colorBrandPrimaryDark;
            }
        }
    }
}
.detailBlock-CTA {
    @include mq(med) {
        position:absolute;
        top:100%;
        width:100%;
        text-align:center;
    }
}
.detailBlock--one {
    @include mq(med) {
        figure {
            margin:$spacingSm $spacingBase $spacingSm 0;
        }
    }
}
.detailBlockListing--two {
    @include mq(sm) {
        .detailBlock {
            @include flex-basis(50%);
            width:50% !important;     
            max-width:50% !important;     
        }        
    }
}
.detailBlockListing--three  {
    .detailBlockListing--inner {
        @extend .row--tight;
    }    
    @include mq(667px) {
        .detailBlock {
            @include flex-basis(32.23333%);            
            width:32.23333% !important;
            max-width:32.23333% !important;            
        }
    }
}
