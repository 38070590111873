/* MAIN HEADER
========================================================================== */

.headerMain {
    @include flex(0 0 auto);

    position: relative;
    z-index: 3;
    background: $colorWhite;
    // Target all Header URLs
    a, a:hover {text-decoration:none;}
    // style the classes which make headerMain-main sticky on scroll
    [class*='js-headerFixed'] & {
        position: fixed;
        right: 0;
        left: 0;
    }
    .js-headerFixed--up &, .js-headerFixed--down & {
        top: 0;
        .headerMain-main {margin:0;}
        .headerMain-util {display:none}    
        @include mq(lg) {
            .locBlurb .toggleLinkTarget {display:none;}
        }    
    }
    .js-headerFixed--up & {
        top: 0;
        .headerMain-main {border-bottom:1px solid $colorBorder;}
        @include mq(lg) {
            .headerMain-mainZone03 .navMain, .headerMain-mainZone03 .searchToggle  {display:none}       
        }   
    }    
}
.headerMain--stripped {
    border-bottom:1px solid $colorBorder;
}
// Util Only Enabled for Franchises
.headerMain-util {
    @include font-size(14px);

    padding-right: $gutterSm;
    padding-left: $gutterSm;
    @include mq(xlg) {
        padding-right: $spacingLg;
        padding-left: $spacingLg;
    }
}

.headerMain-utilInner {
    margin:auto;
    @include mq(med) {
        @include flexbox;
        @include justify-content(space-between);
    }
}

.headerMain-utilZone01 {
    @include flexbox;
    @include align-items(center);

    //border: 1px solid green;
    > * {
        @include flex(1 1 auto);
    }
}

.headerMain-utilZone02 {
    @include flexbox;
    @include align-items(center);
    //border: 1px solid blue;
    > * {
        @include flex(1 1 auto);
    }
    @include mq(med) {
        @include justify-content(flex-end);
    }
}

.headerMain-utilZone03 {
    @include flexbox;
    @include align-items(center);
    //border: 1px solid red;
    > * {
        @include flex(1 1 auto);
        height:100%;
    }
}

.headerMain-main {
    transition:all .2s ease-in-out;
    @include mq(xlg) {
        margin-top:-$spacingBase;
        .headerMain--stripped & {
            margin:0;
        }
    }
}
.headerMain-mainInner {
    @extend %flexbox;
    @include justify-content(space-between);
    margin:auto;  
    @include mq(med) {
        @include flex-wrap(wrap);
    }
}

.headerMain-mainZone01 {
    @include flexbox;
    @include align-items(center);
    > * {
        @include flex(1 1 auto);
    }
    @include mq(xsm-only){
        max-width:225px;
    }
    @include mq(xlg) {
        padding-left: $spacingLg;
    }
}

.headerMain-mainZone02 {
    @include flexbox;
    @include align-items(center);
    margin-left: auto;
    > * {
        @include flex(1 1 auto);
    }
    @include mq(lg) {
        padding-right:$spacingLg;
    }
}

.headerMain-mainZone03 {
    @include flexbox;
    @include align-items(center);
    justify-content:center;
    position:relative;   
    border-bottom:1px solid $colorWhite;
    @include mq(lg) {
        background:$colorBrandPrimary;
        @include flex(1 1 100%);
    }
    > .navMain {
        display: none;
        @include mq(lg) {
            display: flex;
        }
    }
}

// Reduce flash loading on mobile
.headerMain-mainZone01 .locBlurb, .headerMain--center .headerMain-mainZone02 .navCallout, .headerMain--franDev .headerMain-mainZone02 .navCallout, 
.headerMain-mainZone02 .locSearch {display:none;}
@include mq(med){
    .headerMain-mainZone01 .locBlurb, .headerMain--center .headerMain-mainZone02 .navCallout, 
    .headerMain--franDev .headerMain-mainZone02 .navCallout {display:inline-block;}
}
@include mq(lg) {
    .headerMain-mainZone02 .locSearch {display:block;}
}
@include mq(1200px) {
    .headerMain-mainZone02 .locSearch {display:flex;}
}