/******************************************************************
Site Name: Site Name
Author: UI Developer Name(s)
Stylesheet: Print Stylesheet
******************************************************************/
@media print {
    @page {
        margin: .5cm;
    }
    * {
        color: black !important;
        background: transparent !important;
        box-shadow: none !important;
        text-shadow: none !important;
        -ms-filter: none !important;
        filter: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;

        color: #444 !important;

        /* show link href values on printed pages*/
        &:after {
            //content: " (" attr(href) ")";
        }
        /* show titles*/
        abbr[title]:after {
            content: ' (' attr(title) ')';
        }
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    /* hide content we don't need to see on the printed page*/
    nav,
    .spotlight-image,
    .footermain-top,
    .headerMainCart,
    .headerMainSearchToggle,
    .headerMain-mobileDrawerToggle,
    .headerMain-mainZone02,
    .headerMain-mainZone03,
    .navOptions {
        display: none !important;
    }

    // This is the fix for firefox printing
    *:not(nav):not(.spotlight-image):not(.footermain-top):not(.headerMainCart):not(.headerMainSearchToggle):not(.headerMain-mobileDrawerToggle):not(a):not(.headerMain-util):not(#stOverlay):not(#stwrapper):not(.headerMain-mainZone02):not(.headerMain-mainZone03):not(head):not(script):not(.navOptions):not(th):not(td):not(tr):not(tbody):not(table):not(.column):not(span):not(strong) {
        display: block !important;
    }
}
