/*  FILLS
========================================================================== */

/*
 * Apply background colors
 */


.h-fillColorBrandPrimary {
    background-color: $colorBrandPrimary !important;
}
.h-fillColorBrandPrimaryLight {
    background-color: $colorBrandPrimaryLight !important;
}
.h-fillColorBrandPrimaryLighter {
    background-color: $colorBrandPrimaryLighter !important;
}
.h-fillColorBrandPrimaryLightest {
    background-color: $colorBrandPrimaryLightest !important;
}
.h-fillColorBrandPrimaryDark {
    background-color: $colorBrandPrimaryDark !important;
}
.h-fillColorBrandPrimaryDarker {
    background-color: $colorBrandPrimaryDarker !important;
}
.h-fillColorBrandPrimaryDarkest {
    background-color: $colorBrandPrimaryDarkest !important;
}

.h-fillColorBrandSecondary {
    background-color: $colorBrandSecondary !important;
}
.h-fillColorBrandSecondaryLight {
    background-color: $colorBrandSecondaryLight !important;
}
.h-fillColorBrandSecondaryLighter {
    background-color: $colorBrandSecondaryLighter !important;
}
.h-fillColorBrandSecondaryLightest {
    background-color: $colorBrandSecondaryLightest !important;
}
.h-fillColorBrandSecondaryDark {
    background-color: $colorBrandSecondaryDark !important;
}
.h-fillColorBrandSecondaryDarker {
    background-color: $colorBrandSecondaryDarker !important;
}
.h-fillColorBrandSecondaryDarkest {
    background-color: $colorBrandSecondaryDarkest !important;
}

.h-fillColorAccent {
    background-color: $colorAccent !important;
}
.h-fillColorAccentLight {
    background-color: $colorAccentLight !important;
}
.h-fillColorAccentLighter {
    background-color: $colorAccentLighter !important;
}
.h-fillColorAccentLightest {
    background-color: $colorAccentLightest !important;
}
.h-fillColorAccentDark {
    background-color: $colorAccentDark !important;
}
.h-fillColorAccentDarker {
    background-color: $colorAccentDarker !important;
}
.h-fillColorAccentDarkest {
    background-color: $colorAccentDarkest !important;
}


.h-fillColorHighlight {
    background-color: $colorHighlight !important;
}
.h-fillColorDanger {
    background-color: $colorDanger !important;
}
.h-fillColorSuccess {
    background-color: $colorSuccess !important;
}
.h-fillColorWarning {
    background-color: $colorWarning !important;
}
.h-fillColorInfo {
    background-color: $colorInfo !important;
}
.h-fillColorGrey {
    background-color: $colorGrey !important;
}
.h-fillColorGreyLight {
    background-color: $colorGreyLight !important;
}
.h-fillColorGreyLighter {
    background-color: $colorGreyLighter !important;
}
.h-fillColorGreyLightest {
    background-color: $colorGreyLightest !important;
}
.h-fillColorGreyDark {
    background-color: $colorGreyDark !important;
}
.h-fillColorGreyDarker {
    background-color: $colorGreyDarker !important;
}
.h-fillColorGreyDarkest {
    background-color: $colorGreyDarkest !important;
}
.h-fillColorBlack {
    background-color: $colorBlack !important;
}
.h-fillColorWhite {
    background-color: $colorWhite !important;
}