/* DROPDOWNS
========================================================================== */

/*
* Basic styles
*/
.flyout {
    position: relative;

    display: inline-block;

    padding: $spacingXSm $spacingSm;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    border: 1px solid $colorGrey;
    background: $colorWhite;
}
.flyout-content {
    @extend %noPhatBottom;
    position: absolute;
    top: 100%;
    left: 0;

    overflow: hidden;

    min-width: 200px;
    padding: $spacingSm;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
            transform-origin: top left;

    color: $colorText !important;
    background: $colorWhite;
    box-shadow: 1px 1px 4px rgba($colorBlack,.1);

    &.flyout-content--left {
        right: -1px;
        left: auto;

        transform-origin: top right;
    }
    > h1, > h2, > h3, > h4, > h5, > h6,
    p,
    ul,
    ol {
        margin-bottom: $spacingXSm;
    }
}
/*
* Modify the styles in case the flyout is a ul with links
*/
ul.flyout-content {
    margin: 0;
    padding: 0;

    list-style: none;

    li {
        margin: 0;

        a {
            display: block;

            padding: $spacingXSm;

            text-decoration: none;

            border-top: 1px solid $colorGreyLighter;

            &:hover {
                background: $colorGreyLightest;
            }
        }
        &:first-child a {
            border-top-color: transparent;
        }
    }
}
.flyout.is-open {
    color: $colorWhite;
    border-color: darken($colorLinksHover, 5%);
    background: $colorLinksHover;
    box-shadow: inset 0 3px 5px rgba($colorBlack,.125);

    .flyout-content {
        z-index: 100;

        height: auto;

        -webkit-transform: scale(1);
            -ms-transform: scale(1);
                transform: scale(1);
    }
}

/*
* The style that looks similar to a select element
*/
.flyout--select {
    padding-right: $spacingLg;

    .flyout-content {
        min-width: 100%;

        -webkit-transform-origin: top center;
            -ms-transform-origin: top center;
                transform-origin: top center;
    }
    &:after {
        position: absolute;
        top: 50%;
        right: 10px;

        width: 0;
        height: 0;
        margin-top: -3px;

        content: '';

        border-top: 7px solid $colorGreyLight;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
    }
    &.is-open {
        box-shadow: 4px 7px 4px -2px rgba($colorBlack, .1);

        &:after {
            border-top: none;
            border-bottom: 7px solid $colorWhite;
        }
    }
}
