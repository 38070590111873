/* PAGE TITLE
========================================================================== */

.pageTitle {
    @extend %flexbox;
    padding:$spacingBase;
    background-repeat: no-repeat;
    background-position: center center !important;
    background-size: cover !important;
    text-align: center;
    align-items:flex-end; 
    background:$colorBrandPrimary;  
    .contained {
        @extend .banner-contained;
        max-width:$maxWidth+40px;
        min-height:200px;
        align-items:center;
        display:flex;
        flex-wrap:wrap;
        width:100%;
        padding:$spacingLg $spacingBase 0 $spacingBase;
    } 
    &.pageTitle--fixedBackground {
        background-attachment: fixed;
    }    
    &[style*='background-image'] {
        // if they dont want to use solid pillar colors uncomment
        min-height:350px;
        background-size:cover;        
    }             

}
.pageTitleCopy {
    @include align-self(flex-end);    
    padding:$spacingBase;
    text-align: left;
    line-height: 1.2;
    min-width:425px;
    figure {display:none;} 
    .pageTitleCopy-content {overflow:hidden;} 
    @include mq(med) {
        width: 625px;
        margin:0 auto 0;
        figure {
            display:inline-block;
            max-width:150px;
            float:left;
            margin:0 $spacingBase 0 0;
        }  
              
    }
}
.pageTitleCopy-heading {
    @extend .bannerCopy-heading;
    overflow:hidden;
    line-height:1.1;
    margin:0;
    padding-bottom:$spacingXSm;
    &:only-child {
        @include font-size(44px);
    }
}
.pageTitleCopy-SubHeading {
    @include responsive-font(5vw, 14px, 16px, 15px);
    line-height:1.4;
    font-family: $fontDefault;
    font-weight: inherit;
    margin:0;
    overflow:hidden;
    &:only-child {
        @include font-size(22px);
    }
    padding: 0 26px;
    @include mq(med) {
        padding: 0;
    }
}

// Color Text White
.pageTitle--textLight {
    color: $colorWhite;
    .pageTitleCopy-heading {color:$colorWhite;}
}
// Add agCircle
.pageTitle--textBackgroundLight, .pageTitle--textBackgroundDark {
    position:relative;
    z-index:1;
    // .contained { padding:$spacingBase;}
    .pageTitleCopy {
        padding:0;        
        color: color-contrast(rgba($colorWhite, .95));
        background: rgba($colorWhite, .95);
        border-radius:50%;
        position:relative;
        text-align:center;
        &:before {
            content: " ";
            background: url('#{$pathTemplateImages}/ag-w.svg');
            background-size: contain;
            width: 1000px;
            height: 1000px;
            display: inline-block;
            font-family:$fontIcon;
            position:absolute;
            z-index:-1;
            left:-100%;
            right:-100%;
            top:-($spacingLg*1.5);
            margin:auto;
            // bottom:$spacingBase;
        }         
        .pageTitleCopy-heading {color:color-contrast(rgba($colorWhite, .95));}
        .pageTitleCopy-Subheading {
            max-width:475px;
        }
    }
}
// Circle red option
.pageTitle--textBackgroundDark {
    color: $colorWhite;
    .pageTitleCopy {
        color: color-contrast(rgba($colorBlack, .9));
        background:$colorBrandPrimaryDarkest;
        &:before {
            background: url('#{$pathTemplateImages}/ag-rd.svg');
            background-size: contain;            
        }
        .pageTitleCopy-heading {color:color-contrast(rgba($colorBlack, .9));}        
    }
    &[style*='background-image']{ 
        .pageTitleCopy {
            background:$colorBrandPrimary;
            &:before {
                background: url('#{$pathTemplateImages}/ag-r.svg');
                background-size: contain;            
            }
        }        
    }
}
.pageTitle.pageTitle--tall {
    min-height:400px;
}     
.pageTitle.pageTitle--Xtall {
    min-height:450px;
}


@include mq(med) {
    .pageTitle {
        .contained {padding:0;}
        .pageTitleCopy {
            text-align:left;
            &:before {
                width: 1400px;
                height: 1400px; 
                top:-($spacingXLg*1.75);
                left:-615px;                               
            }
        }

    }
    .pageTitle--left,
    .pageTitle--left .pageTitleCopy {
        text-align: left;
        margin-left:0 !important;
    }
    .pageTitle--center {
        text-align:center;
        .pageTitleCopy {
            text-align:center;
        }
    }
    .pageTitle--right,
    .pageTitle--right .pageTitleCopy {
        text-align: left;
        margin-right:0 !important;        
    }
    .pageTitle--textBackgroundLight {
        .pageTitleCopy {       
            .pageTitleCopy-heading {color:color-contrast(rgba($colorWhite, .95));}
            .pageTitleCopy-Subheading {
                max-width:475px;
            }
        }
    } 
    .pageTitle--textBackgroundDark, .pageTitle--textBackgroundLight {
        min-height:375px;
    }
    .pageTitle.pageTitle--tall {
        min-height:500px;
    }     
    .pageTitle.pageTitle--Xtall {
        min-height:600px;
    }           

}
