/* MAX-WIDTH
========================================================================== */

/*
 * This value is used for constrained elements like grid rows and the container class
 * for the widest you want your page content to display
 *
 * See _components/_grid.scss
 * and _components/_container.scss
 */
$maxWidth: 1120px;




