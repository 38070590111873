/* GALLERY
========================================================================== */

.gallery { padding:$spacingLg 0; }
.gallery-images {
    @extend %flexbox;

    @include flex-flow(row wrap);
    @include justify-content(center);

    margin-right: -$gutterSm;
    margin-left: -$gutterSm;
}

.gallery-heading {
    text-align: center;
}
.gallery-figure {
    margin: 0;
    padding-right: $gutterSm;
    padding-left: $gutterSm;

    img {
        display: inline-block;
        margin:0;
    }
    figcaption {
        @extend .h-visuallyHidden;
    }
    > a {
        @extend .icon-plus-alt;
        position: relative;
        display: block;
        cursor: zoom-in;
        text-decoration:none;

        &:before,
        &:after {
            transition:all .35s ease-in-out;
            opacity: 0;
        }
        &:before {
            @extend %iconStyle;

            position: absolute;
            z-index: 2;
            top: 70%;
            left: 70%;

            margin-top: -.5em;
            margin-left: -.5em;

            font-size: 30px;

            color: $colorWhite;
        }
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            content: '';

            opacity: 0;
            background: rgba($colorBrandPrimary,.8);
        }
        &:hover {
            &:before,
            &:after {
                opacity: 1;
            }
            &:before {
                top: 50%;
                left: 50%;
            }
        }
    }

    @include mq(sm) {
        width: 50%;
    }
    @include mq(med) {
        width: 33.3333333333%;
    }
    @include mq(lg) {
        width: 25%;
    }
}
.gallery--slider {
    .slick-prev,
    .slick-next {
        top:35%;
        width: 30px;
        height: 30px;
        background:$colorWhite; 
        border:1px solid $colorBorder;       
        &:before {
            margin: 0;
            font-size: 24px;
            line-height:1.2;
            color:$colorLinks;
        }
        &:hover {
            background:$colorLinksHover;
            &:before {color:$colorWhite;}
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    .slick-track {
        margin-right: auto;
        margin-left: auto;
    }
    .gallery-figure {
        width: auto;
        padding-right: $gutterXSm;
        padding-left: $gutterXSm;
    }
    @include mq(xlg) {
        .contained {max-width:1250px;}
    }
}
.gallery-images.slick-slider {
    margin: 0;
    padding-right: $spacingLg;
    padding-left: $spacingLg;
}
