/* FIGURE
========================================================================== */

figure {
	text-align: center;
	margin: 0 0 $spacingBase 0;
	position: relative;
	
	/*
	* Image captions
	*/
	figcaption {
		@include font-size($fontSizeBase*.85);
		line-height:1.3;
		display: block;
		color: $colorGreyDarker;
		font-style:italic;
		padding-bottom: 1em;
		margin-top: 1em;
		border-bottom:1px solid $colorBorder
	}
}
