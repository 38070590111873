/* BUTTONS
========================================================================== */
/*
* We usually like to keep away from nesting/over specificity, but since we are including input types here
* as well as classes we need to so all styles are available to those input types as well
*/

.btn,
input[type='submit'],
input[type='button'],
input[type='reset'] {
    transition:all .25s ease-in-out;
    @include font-size(15px);
    font-weight:normal;
    display: inline-block;
    letter-spacing:0.025em;

    width: auto; // For button type inputs. Maybe don't mess with this ;)
    margin-bottom: 0; // For button type inputs. Maybe don't mess with this ;)
    padding: $spacingXSm $spacingBase;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;

    color: $colorWhite;
    border: 1px solid $btnBorderColor; // For button type inputs. Maybe don't mess with this ;)
    border-radius:4px;
    background: $btnBg;

    -webkit-appearance: none; //Remove rounded corners on iPhone
    touch-action: manipulation; // Remove 300ms touch delay

    &:hover {
        color: color-contrast($btnHoverBg);
        border-color: $btnHoverBorderColor;
        background: $btnHoverBg;
        text-decoration:none !important;
    }
    &:active,
    &:focus {
        outline: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        pointer-events: none; // Future-proof disabling of clicks

        opacity: .65;
    }
    /* VARIANTS
  ========================================================================== */
    &.btn--secondary {
        color: $colorLinks;
        border-color: $btnSecondaryBorderColor;
        background: $btnSecondaryBg;

        &:hover {
            color: $colorWhite;
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }
    &.btn--white {
        border-color:$colorWhite;
        background:$colorWhite;
        color:$colorBrandPrimary;
        &:hover {
            background:$colorBrandPrimary;
            color:$colorWhite;
        }
    }
    &.btn--whiteGhost {
        background:transparent;
        border-color:$colorWhite;
        color:$colorWhite;
        &:hover {
            background:$colorWhite;
            color:$colorBrandPrimary;
        }
    }
    /*
   * These buttons will fill the width of their container.
   */
    &.btn--full {
        display: block;

        width: 100%;
    }
    .btn--full + .btn--full {
        margin-top: $spacingXSm;
    }
    /*
  * This button inherits font and other aspects of it's parent naturally
  */
    &.btn--natural {
        padding-right: .5em;
        padding-left: .5em;

        vertical-align: baseline;
        font-size: inherit;
        line-height: inherit;
    }
    &.btn--bold {
        font-weight: bold;
    }
    /* SIZES
  ========================================================================== */
    /*
   * Button size modifiers.
   */
    &.btn--XSm {
        @include font-size($fontSizeXSm);

        padding: 0 $spacingSm*.75;
    }
    &.btn--sm {
        @include font-size($fontSizeSm);

        padding: $spacingXSm/2 $spacingSm;
    }
    &.btn--lg {
        @include font-size($fontSizeLg*.8);

        padding: $spacingSm $spacingBase*1.25;
    }
    &.btn--XLg {
        @include font-size($fontSizeLg);

        padding: $spacingSm*1.25 $spacingBase*1.5;
    }
}/*end .btn*/
