/* SECONDARY NAV
========================================================================== */ 

/*
* !!! we are using the class of .lastSelected instead of .selected to
* target the current page link because iAPPS nav control adds .selected
* to both the the direct parent LI of the current page link and also IT's parent
* if it is a nested link
 <nav class="navSecondary">
		<ul>
		<li class="selected">
			<a href="">We aint here</a>
			<ul>
				<li class="selected">
					<a href="">We're actually here. Screwy huh?</a>
				</li>
			</ul>
		</li>
 </nav>
 *
 * There's a lil snippet in frontEnd.js that adds the class .lastSelected to the last
 * instance of .selected to tell us where we really are.
 */

 .navSecondary {
 	@extend %clearfix;

 	@include font-size(13px);
 	border-bottom:1px solid $colorBrandPrimaryDark; 	
	ul {
		@extend %clearfix;
		margin:0;
		padding:0;
		list-style:none;
	}

	li {
		@extend %clearfix;
	}

	.active a {
		font-weight:bold;

		@include mq(med) {
		}
	}

	a {
		display:block;

		padding:$spacingXSm;

		text-decoration:none;

		color:$colorText;

		&:hover {
			color:$colorLinksHover;
			text-decoration:underline;
		}
	}
}
.navSecondary-breadcrumb {
    li {
        display:none;

        &:first-of-type {
            display:block;

            border-bottom:1px solid $colorBrandPrimaryDark;
        }
    }
}
@include mq(med) {
	.navSecondary {border:none;}
    .navSecondary-breadcrumb {
        li:first-of-type {
            margin:0;
            border-width:0;
        }

        a {
            &:after {
                @include iconStyle;

                margin-right:0;
                margin-left: $spacingXSm;

                content: '\e98a';

                font-size:100%;

                color: $colorGrey;
                speak:none;
            }
            &:hover:after {text-decoration:none;}
        }
    }
}

.navSecondaryToggle {
	position:relative;
	z-index:2;

	display:inline-block;
	float:right;

	padding: $spacingXSm $spacingSm;

	cursor:pointer;
	text-align:center;

	&:before {
		@include iconStyle;

		margin-right:0;

		content: '\e813';

		color: $colorWhite;
	}

	&.is-active {
		&:before {
			content: '\e812';
		}
	}

	@include mq(med) {
		display:none;
	}
}
.navSecondary-links {
	display:none;

	border-bottom:1px solid $colorBrandPrimaryDark;

	li {
		float:left;
		width:50%;
	}
}
.is-active { 
	.navSecondary-links, .navSecondary-breadcrumb li {
		display:block;
	}
}
@include mq(med) {
	.navSecondary {
		display:flex;
		ul {
			float:left;
			padding:$spacingXSm 0;
			li {
				white-space:nowrap;
				display:block;
				float:left;
				width:auto;
				margin-right:$spacingXSm;
				a {padding:0 $spacingXSm;}
				ul {display:none;}
			}
		}
	}

	.navSecondary-links {
		display:block;

		border-width:0;
	}
}
