/* Nav Callout - this is phone numbers, hours
========================================================================== */
.navCallout, .navCTA {
    @include font-size(15px);  
    i.placeholder {display:none;}
    i {
      margin:0 $spacingXSm*.75 0 0; 
      &:before {
        vertical-align:initial !important;
      }
    }
    > ul {
        @extend %flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        margin: 0;
        padding: 0;
        list-style: none;
        > li {
            text-align: center;
            > a, > span {
              display: block;
              padding: $spacingXSm $spacingSm;
              font-weight:normal;
              color:$colorGreyDark;
            }
            &.btn {
              @include font-size(14px);
              padding:$spacingXSm*.5 $spacingSm*1.25;
              > a {
                color:$colorWhite;
                padding:0;
                white-space:nowrap;
              }
            }
            &:hover {
                > a {
                    text-decoration:underline;
                }
            }        
        }
    }

    // CENTER specific
    .headerMain--center & {
      &.moved {
        margin:0 0 0 (-$spacingSm*1.1);
        > ul {
          @include justify-content(flex-start);
        }
        a, span {color:$colorWhite}
        @include mq(lg) {
          a, span {color:$colorLinks}
        }
      }
      >ul >li:last-child a {
        padding-right:0;
      }
      @include mq(lg) {
        i {display:block;}
      }
      @include mq(1200px) {
        i {display:inline-block;}
      }      
    }
}

.headerMain--franDev {
  li.btn.navCallout-moved {
    padding:$spacingXSm $spacingSm;
    a {color:$colorWhite;}
  }
}
/* Styles to address nav callout items that have children when viewed on mobile screens.
   This is necessary because navcallout does not end up in the drawer like the other 
   util navs. See skyline steel design for an example */
// .headerMain-util {
//    position: relative;
//    .navCallout {
//       > ul {
//          > li {
//           white-space:nowrap;
//            &.hasChildren {
//               position: static;
//               > ul {
//                  @include font-size(13px);
//                  transition: transform .2s ease-in-out;
//                  max-width: none;
//                  width: 100%;
//                  background: rgba(14,116,78,1);
//                  > li {
//                     > a {
//                        padding: $spacingXSm;
//                     }
//                  }
//               }
//               &.is-open {
//                  background: $colorBrandPrimaryDark;
//                  ul {
//                     transform: scale(1, 1);
//                  }
//               }
//               @include mq(lg) {
//                  position: relative;
//               }
//            }
//         }
//       }
//    }
// }