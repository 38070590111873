/*  MARGINS
========================================================================== */

/*
* Add/remove margins
*/

.h-push {
    margin: $spacingBase !important;
}
.h-pushTop {
    margin-top: $spacingBase !important;
}
.h-pushRight {
    margin-right: $spacingBase !important;
}
.h-pushBottom {
    margin-bottom: $spacingBase !important;
}
.h-pushLeft {
    margin-left: $spacingBase !important;
}
.h-pushEnds {
    margin-top: $spacingBase !important;
    margin-bottom: $spacingBase !important;
}
.h-pushSides {
    margin-right: $spacingBase !important;
    margin-left: $spacingBase !important;
}

.h-pushXSm {
    margin: $spacingXSm !important;
}
.h-pushXSmTop {
    margin-top: $spacingXSm !important;
}
.h-pushXSmRight {
    margin-right: $spacingXSm !important;
}
.h-pushXSmBottom {
    margin-bottom: $spacingXSm !important;
}
.h-pushXSmLeft {
    margin-left: $spacingXSm !important;
}
.h-pushXSmEnds {
    margin-top: $spacingXSm !important;
    margin-bottom: $spacingSm !important;
}
.h-pushXSmSides {
    margin-right: $spacingXSm !important;
    margin-left: $spacingSm !important;
}
.h-pushSm {
    margin: $spacingSm !important;
}
.h-pushSmTop {
    margin-top: $spacingSm !important;
}
.h-pushSmRight {
    margin-right: $spacingSm !important;
}
.h-pushSmBottom {
    margin-bottom: $spacingSm !important;
}
.h-pushSmLeft {
    margin-left: $spacingSm !important;
}
.h-pushSmEnds {
    margin-top: $spacingSm !important;
    margin-bottom: $spacingXSm !important;
}
.h-pushSmSides {
    margin-right: $spacingSm !important;
    margin-left: $spacingXSm !important;
}

.h-pushLg {
    margin: $spacingLg !important;
}
.h-pushLgTop {
    margin-top: $spacingLg !important;
}
.h-pushLgRight {
    margin-right: $spacingLg !important;
}
.h-pushLgBottom {
    margin-bottom: $spacingLg !important;
}
.h-pushLgLeft {
    margin-left: $spacingLg !important;
}
.h-pushLgEnds {
    margin-top: $spacingLg !important;
    margin-bottom: $spacingLg !important;
}
.h-pushLgSides {
    margin-right: $spacingLg !important;
    margin-left: $spacingLg !important;
}

.h-pushXLg {
    margin: $spacingXLg !important;
}
.h-pushXLgTop {
    margin-top: $spacingXLg !important;
}
.h-pushXLgRight {
    margin-right: $spacingXLg !important;
}
.h-pushXLgBottom {
    margin-bottom: $spacingXLg !important;
}
.h-pushXLgLeft {
    margin-left: $spacingXLg !important;
}
.h-pushXLgEnds {
    margin-top: $spacingXLg !important;
    margin-bottom: $spacingXLg !important;
}
.h-pushXLgSides {
    margin-right: $spacingXLg !important;
    margin-left: $spacingXLg !important;
}

.h-flush {
    margin: 0 !important;
}
.h-flushTop {
    margin-top: 0 !important;
}
.h-flushRight {
    margin-right: 0 !important;
}
.h-flushBottom {
    margin-bottom: 0 !important;
}
.h-flushLeft {
    margin-left: 0 !important;
}
.h-flushEnds {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.h-flushSides {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
