/* GUTTERS
========================================================================== */

/*
 * Define gutter width for rows, grid and container.
 *
 * See _components/_grid.scss
 * and _components/_container.scss
 *
 * There are two values here as I like to use a smaller gutter on small screens.
 * To only use one gutter width across all screens, comment out or delete the media query
 * in the above mentioned scss files
 */
$gutterBase: $fontSizeBase;
$gutterSm: $gutterBase/2;
$gutterXSm: $gutterSm/2;
$gutterLg: $gutterBase*2;
$gutterXLg: $gutterLg*2;




