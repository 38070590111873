/* SECTION
========================================================================== */

%section {
    padding: $spacingBase;
    @include mq(lg) {
        padding-top:$spacingLg*1.5;
        padding-bottom:$spacingLg*1.5;
    }
    // Parallax is adding a scroll bar to sections
    overflow:hidden;
    &.section--short {
        padding-top:$spacingSm;
        padding-bottom:$spacingSm;
        @include mq(lg) {
            padding-top:$spacingBase;
            padding-bottom:$spacingBase;            
        }
    }
    &.section--narrow {
        max-width:800px;
    }
}
.section {
    @extend %section;
    &.section--border {
        padding:0;
        border-bottom:50px solid $colorWhite;
        &.borderDark {border-color:$colorBrandPrimary;}
        &.borderLight {border-color:$colorGreyLightest}
        &.borderBlue {border-color:$colorAGBlue;}
        &.borderYellow {border-color:$colorAGYellow;}
        &.borderBrick {border-color:$colorAGBrick;}
        &.borderTeal {border-color:$colorAGTeal;}
    }
    &--contrast {
        color: color-contrast($colorAccent);
        background-color: $colorAccent;
    }
    &--contrastLight {
        color: color-contrast($colorGreyLightest);
        background-color: $colorGreyLightest;
        .spotlight-content:before {
            display:none;
        }
        input[objecttype='textbox'],
        .formTextBox,
        input[type='text'],
        input[type='search'],
        input[type='email'],
        input[type='tel'],
        input[type='date'],
        input[type='datetime'],
        input[type='datetimelocal'],
        input[type='month'],
        input[type='number'],
        input[type='password'],
        input[type='color'],
        input[type='range'],
        input[type='file'],
        textarea,
        select {
            background-color:$colorWhite;
        }  
        &.pageTitle {
            background-color:$colorGreyLightest;
        }      
    }
    &--contrastDark {
        color: color-contrast($colorBrandPrimary);
        background-color: $colorBrandPrimary;
        a, p, .featureBlock-subHeading a, .featureBlock-figcaption, 
        .reviews-byline, .socialShare-heading, .socialShare-icons, 
        .bannerCopy-heading, .marketingDownload-inner .marketingDownload-subheader,
        .form-body label {color:$colorWhite;}
        &.spotlight {
            margin:$spacingBase 0;
            .spotlight-inner {padding:0}
            .spotlight-content:before {
                background: url('#{$pathTemplateImages}/ag-r.svg');
            } 
            @include mq(lg){
                margin:$spacingLg 0;
            }           
        }
        .btn, input[type="submit"] {
            @extend .btn--white
        }
        .slick-dots li {
            button:before {
                background-color:$colorWhite;
                opacity:.5;

            }
            &.slick-active button:before {
                opacity: 1;
                background-color: $colorWhite;
            }
        }
    }

    // &--ornament {
    //     position:relative;
    //     > * {
    //         position:relative;
    //         z-index:1;
    //     }
    //     &:before, &:after {
    //         content:'';
    //         display:block;
    //         position:absolute;
    //         top:-85%;
    //         right:0;
    //         height:800px;
    //         width:800px;
    //         border-radius:50%;
    //         background:$colorGreyLight;
    //     }
    //     &:after {
    //         background:$colorGreyLightest;
    //         top:-85%;
    //         right:0;
    //     }
    // }
    // &--ornamentDark {
    //     background:$colorBrandPrimary;
    //     color:$colorWhite;
    //     &:before, &:after {
    //         background:$colorBrandPrimaryDark;
    //     }
    // }
}
.detailBlock.detailBlock--contrastLight, .detailBlock.detailBlock--contrastDark {
    .detailBlock-inner{
        color: color-contrast($colorGreyLightest);
        background-color: $colorGreyLightest;
        .detailBlock-content {
            p,img, h1,h2,h3,h4,h5,h6 {
                padding:0 $spacingSm;
            }
            // h1,h2,h3,h4,h5,h6 {
            //     padding-bottom:0;
            // }        
        }
        .detailBlock-heading {
            padding:$spacingSm;
            ~ .detailBlock-content img:first-child {
                padding-top:0
            }
        }            
    }
    &.detailBlock--imgRight, &.detailBlock--imgLeft {
        figure {margin:$spacingSm !important}   
        .detailBlock-inner .detailBlock-heading {
            ~ .detailBlock-content img:first-child {
                padding-top:$spacingSm !important;
            }
        }              
    }    
}
.detailBlock.detailBlock--contrastDark .detailBlock-inner {
    color: color-contrast($colorBrandPrimary);
    background-color: $colorBrandPrimary; 
    .detailBlock-content a, .detailBlock-heading a {
        color:$colorWhite;
        &.btn {@extend .btn--white;}
        &:hover {text-decoration:underline;}
    }   
}
