/* PAGE META
========================================================================== */

.pageMeta {
	background:$colorGreyLighter;
	// border-bottom:1px solid $colorBorder;
}	
.pageMeta-inner {
	@extend %clearfix;
	@include mq(med) {
		padding-right:$gutterBase;
		padding-left:$gutterBase;
	}
	@include mq(xlg) {
		padding-right:$spacingLg;
		padding-left:$spacingLg;
	}
}

//***********************



