/* SEARCH BAR
========================================================================== */ 

/* Searchbox/button */
.searchBox {
	text-align:right;
	margin-bottom:$spacingBase;
	position:relative;
}
.searchBox-heading {
	span {
		font-weight:normal;
		font-style:italic;
		font-size:80%;
	}
}
.searchBox-textField {
	padding-right: 43px;
	margin:0;
	height:38px;
	line-height:38px;
	border-color:$colorGreyLight;
	color:darken($colorGrey, 45%);
}
.searchBox-submit {
	@extend .icon-search;
	background: $colorGreyLight;
	display:block;
	position:absolute;
	right:0;
	top:0;
	color:$colorWhite;
	border-radius: 0 2px 2px 0;
	height:38px;
	width:38px;
	line-height:38px;
	text-align:center;
	text-decoration:none;
	&:before {
		@extend %iconStyle;
		margin:0;
	}
	&:hover {
		color:$colorWhite;
	}
}
.searchBox--hiVis {
	margin-bottom:$spacingLg*.75;
	.searchBox-textField {
		border-color:$colorBrandPrimary;
	}
	.searchBox-submit {
		background: $colorBrandPrimary;
	}
}
.searchBox--main {
	@include font-size(20px);
	color:$colorWhite;
	.searchBox-textField {
		background:transparent;
		border-top-width:0;
		border-right-width:0;
		border-left-width:0;
		border-color:$colorWhite !important;
		&:focus {
			color:$colorWhite;
		}
	}
	.searchBox-submit {
		background:transparent;

	}
	::-webkit-input-placeholder {
	   color: $colorWhite;
	   opacity:1;
	   text-transform:uppercase;
	   font-weight:bold;
	}

	:-moz-placeholder { /* Firefox 18- */
	   color: $colorWhite; 
	   opacity:1;
	   text-transform:uppercase;
	   font-weight:bold;
	}

	::-moz-placeholder {  /* Firefox 19+ */
	   color: $colorWhite;
	   opacity:1;
	   text-transform:uppercase;
	   font-weight:bold;
	}

	:-ms-input-placeholder {  
	   color: $colorWhite;
	   opacity:1;
	   text-transform:uppercase;
	   font-weight:bold;
	}
}

