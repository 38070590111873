/* SLICK SLIDER
========================================================================== */

//Base styles

.slick-slider {
    position: relative;

    display: block;

    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
    -khtml-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;

    &:before,
    &:after {
        display: table;

        content: '';
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;

    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
        margin:$spacingSm;
    }
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;

        height: auto;

        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

//theming

$slick-loader-path: "./" !default;
$slick-arrow-color: $colorWhite !default;
$slick-arrow-background: $colorBrandPrimary;
$slick-dot-color: $colorGreyLight !default;
$slick-dot-color-active: $colorGreyDarkest !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 70px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    top: 20%;

    display: block;

    width: 35px;
    height: 50px;
    padding: 0;

    cursor: pointer;
    -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
    font-size: 0;
    line-height: 0;

    color: $slick-arrow-color;
    border: none;
    outline: none;
    background: transparent;
    z-index:1;
    text-align:center;
    background:$slick-arrow-background;
    &:hover,
    &:focus {
        outline: none;
        background: $colorBrandPrimaryDarkest;

        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        @extend %iconStyle;
        font-size:36px;
        line-height:32px;
    }
    .slick-vertical & {
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0);
    }
    @include mq(med) {
        top:50%;
    }
}

.slick-prev {
    @extend .icon-caret-left;
    left: 0px;
    &:before {
        margin-left:-3px;
    }

    .slick-vertical & {
        @extend .icon-caret-up;
    }
}

.slick-next {
    @extend .icon-caret-right;
    right: 0px;
    &:before {margin-right:0}
    .slick-vertical & {
        @extend .icon-caret-2down;
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    top:100%;

    display: block;

    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    text-align: center;

    li {
        position: relative;
        display: inline-block;

        width: 15px;
        height: 15px;
        margin: 0 5px;
        padding: 0;

        cursor: pointer;

        button {
            display: block;

            width: 15px;
            height: 15px;
            padding: 5px;

            cursor: pointer;
            font-size: 0;
            line-height: 0;

            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    opacity: 1;
                    background:$colorBrandPrimary;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                //margin-top:-.5em;

                width: 13px;
                height: 13px;

                content: '';
                //text-align: center;
                //font-family: $slick-font-family;
                //font-size: $slick-dot-size;
                //-webkit-font-smoothing: antialiased;
                //line-height: 1em;
                background-color:$slick-dot-color;
                border-radius:50%;

                opacity: $slick-opacity-not-active;
                //color: $slick-dot-color;

                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            opacity: $slick-opacity-default;
            background-color: $slick-dot-color-active;
        }
    }
}
