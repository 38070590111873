/* Badges
========================================================================== */

/*
 * Create badge runs of text, e.g.:
 *
   <p>This <span class=badge>here</span> is a badge!</p>
 *
 */
.badge {
    display: inline-block;
    /*
     * These numbers set in ems mean that, at its narrowest, a badge will be
     * the same width as the `line-height` set on the `html` element.
     * This allows us to use the `.badge` in almost any `font-size` we wish.
     */

    min-width: ($lineHeightRatio * .666667) * 1em;
    padding-right: ($lineHeightRatio * .166667) * 1em;
    padding-left: ($lineHeightRatio * .166667) * 1em;

    text-align: center;
    /*
     * Normally we’d use border-radius:100%; but instead here we just use an
     * overly large number; `border-radius:100%;` would create an oval on
     * non-square elements whereas we just want to round the ends of an element.
     */

    border-radius: 100px;
    background-color: $colorGreyLighter;
}
