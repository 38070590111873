/* LINKS
========================================================================== */

a {
    cursor: pointer;
    text-decoration: underline;

    color: $colorLinks;

    &:hover {
        color: $colorLinksHover;
    }
    //make phone links look like regular copy if there is no touch - .no-touch class requires modernizr
    .no-touch &[href^='tel:'] {
        cursor: default;
        pointer-events: none;

        color: inherit;
    }
}
