/* VERTICAL RYTHM
========================================================================== */

/*
 * Where `margin-bottom` is concerned, this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */

/*
 * Base elements
 */
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dl,
blockquote,
p,
address,
fieldset,
figure,
pre,
/*
 * Objects and abstractions
 */
%sass-margin-bottom,
.island,
.islet {
    margin-top: 0;
    margin-bottom: $spacingBase;
    margin-bottom: ($spacingBase / $fontSizeBase)*1rem;
}


/*
 * Doubled up `margin-bottom` helper class.
 */
.landmark {
    margin-bottom: 2 * $spacingBase;
    margin-bottom: (2 * $spacingBase / $fontSizeBase)*1rem;
}


/*
 * `hr` elements only take up a few pixels, so we need to give them special
 * treatment regarding vertical rhythm.
 */
hr {
    margin-bottom: $spacingBase - 2px;
    margin-bottom: (($spacingBase - 2px) / $fontSizeBase)*1rem;
}
