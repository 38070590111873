/*  MUTED
========================================================================== */

/*
 * Mute an object by reducing its opacity.
 */

.h-muted {
    filter: alpha(opacity = 50)!important;

    opacity: .5!important;
}


