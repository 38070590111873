/* FEATURE BLOCK
========================================================================== */

// .listImages {}
.listImages-figure {
	@extend %noPhatBottom;
	margin-bottom:$spacingBase;
	img {margin-bottom:$spacingSm;}
}
.listImages-figcaption {
	padding-bottom:0;
	border-bottom-width:0;
	font-style:initial;
	p {
		margin-bottom:$spacingSm;
	}	
}
.listImages-subHeading {
	@extend .featureBlock-subHeading;
	font-weight:normal;
}