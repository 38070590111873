/*  PADDING
========================================================================== */

/*
* Add/remove paddings
*/

.h-soft {
    padding: $spacingBase !important;
}
.h-softTop {
    padding-top: $spacingBase !important;
}
.h-softRight {
    padding-right: $spacingBase !important;
}
.h-softBottom {
    padding-bottom: $spacingBase !important;
}
.h-softLeft {
    padding-left: $spacingBase !important;
}
.h-softEnds {
    padding-top: $spacingBase !important;
    padding-bottom: $spacingBase !important;
}
.h-softSides {
    padding-right: $spacingBase !important;
    padding-left: $spacingBase !important;
}

.h-softXSm {
    padding: $spacingXSm !important;
}
.h-softXSmTop {
    padding-top: $spacingXSm !important;
}
.h-softXSmRight {
    padding-right: $spacingXSm !important;
}
.h-softXSmBottom {
    padding-bottom: $spacingXSm !important;
}
.h-softXSmLeft {
    padding-left: $spacingXSm !important;
}
.h-softXSmEnds {
    padding-top: $spacingXSm !important;
    padding-bottom: $spacingXSm !important;
}
.h-softXSmSides {
    padding-right: $spacingXSm !important;
    padding-left: $spacingXSm !important;
}

.h-softSm {
    padding: $spacingSm !important;
}
.h-softSmTop {
    padding-top: $spacingSm !important;
}
.h-softSmRight {
    padding-right: $spacingSm !important;
}
.h-softSmBottom {
    padding-bottom: $spacingSm !important;
}
.h-softSmLeft {
    padding-left: $spacingSm !important;
}
.h-softSmEnds {
    padding-top: $spacingSm !important;
    padding-bottom: $spacingSm !important;
}
.h-softSmSides {
    padding-right: $spacingSm !important;
    padding-left: $spacingSm !important;
}

.h-softLg {
    padding: $spacingLg !important;
}
.h-softLgTop {
    padding-top: $spacingLg !important;
}
.h-softLgRight {
    padding-right: $spacingLg !important;
}
.h-softLgBottom {
    padding-bottom: $spacingLg !important;
}
.h-softLgLeft {
    padding-left: $spacingLg !important;
}
.h-softLgEnds {
    padding-top: $spacingLg !important;
    padding-bottom: $spacingLg !important;
}
.h-softLgSides {
    padding-right: $spacingLg !important;
    padding-left: $spacingLg !important;
}

.h-softXLg {
    padding: $spacingXLg !important;
}
.h-softXLgTop {
    padding-top: $spacingXLg !important;
}
.h-softXLgRight {
    padding-right: $spacingXLg !important;
}
.h-softXLgBottom {
    padding-bottom: $spacingXLg !important;
}
.h-softXLgLeft {
    padding-left: $spacingXLg !important;
}
.h-softXLgEnds {
    padding-top: $spacingXLg !important;
    padding-bottom: $spacingXLg !important;
}
.h-softXLgSides {
    padding-right: $spacingXLg !important;
    padding-left: $spacingXLg !important;
}

.h-hard {
    padding: 0 !important;
}
.h-hardTop {
    padding-top: 0 !important;
}
.h-hardRight {
    padding-right: 0 !important;
}
.h-hardBottom {
    padding-bottom: 0 !important;
}
.h-hardLeft {
    padding-left: 0 !important;
}
.h-hardEnds {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.h-hardSides {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
