/* COLORS
========================================================================== */

/*default buttons*/
$btnBg: $colorBrandPrimary !default;
$btnBorderColor: $colorBrandPrimary !default;
$btnHoverBg: $colorBrandPrimaryDarker !default;
$btnHoverBorderColor: $colorBrandPrimaryDarker !default;

/*secondary buttons*/
$btnSecondaryBg: transparent !default;
$btnSecondaryBorderColor: $colorBrandPrimary!default;
$btnSecondaryHoverBg: $colorBrandPrimary !default;
$btnSecondaryHoverBorderColor: $colorBrandPrimary !default;

