/*  TEXT ALIGNMENT
========================================================================== */

/*
* Text alignment
*/

.h-textLeft {
    text-align: left  !important;
}
.h-textCenter {
    text-align: center!important;
}
.h-textRight {
    text-align: right !important;
}

