/*  CAPS
========================================================================== */

/*
 * Apply capital case to an element (usually a `strong`).
 */

.h-caps {
    text-transform: uppercase!important;
}


.h-normal {
	font-weight:normal !important;
	text-transform:initial !important;
}


